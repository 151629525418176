import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toast: ToastController,
    public load: LoadingController) { }
    isLoading = false;

  async present(msg: string, cssClass: boolean = true, time = 2000) {
    let css;
    cssClass? css = 'ajo-toast' : css = 'ajo-toast-error'

    const toast = await this.toast.create({
      message: msg,
      duration: time? time : 2000,
      cssClass: css
    });
    toast.present();
  }

  async presentClose(msg: string, cssClass: boolean = true) {
    let css;
    cssClass? css = 'ajo-toast' : css = 'ajo-toast-error'

    const toast = await this.toast.create({
      message: msg,
      cssClass: css,
      buttons: [
              {
          text: 'x',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          }
        }
      ]
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
   // console.log('onDidDismiss resolved with role', role);
    
    toast.present();
  }
  async loader(text?, cssClass?, duration?,spinner?) {
    this.isLoading = true
    const loading = await this.load.create({
      cssClass: cssClass? cssClass: 'loader',
      message: text? text: '',
      duration:duration? duration: 5000,
      mode: "ios",
      spinner: spinner? spinner : "circular"
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    this.isLoading = false

   // console.log('Loading dismissed!');
  }
  dismissLoading() {
    this.isLoading && this.load.dismiss()
    this.isLoading = false; 
  }


  

}

import { PhotoService } from './../../../services/photo.service';
import { ApiService } from './../../../services/api.service';
import { Customer, User } from './../../../models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-footerbox',
  templateUrl: './footerbox.component.html',
  styleUrls: ['./footerbox.component.scss'],
})
export class FooterboxComponent implements OnInit {

  constructor(public auth : AuthService, public api : ApiService,
    public ps: PhotoService, public ds: DomSanitizer) { }


  async ngOnInit() {
    this.ps.isReady = false
    await this.ps.loadSaved().then(()=> {
      this.ps.isReady = true
    })
  }




}

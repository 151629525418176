import { ApiService } from './../services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BoardingGuard implements CanActivate, CanLoad {
  constructor(public auth : AuthService, public api : ApiService, public router : Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     
      if(this.auth.boardedSeen) {
        this.router.navigate(['/login'])
       return false
     }
     return !this.auth.boardedSeen

    //return this.auth.boardedSeen;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const isLogged = this.auth.isLoggedIn
      const isBoarded = this.auth.isBoarded
      

      if (isBoarded && isLogged ) {
        this.router.navigate(['/main/home'],{replaceUrl: true})
        return false
      } else if (isBoarded && !isLogged) {
        this.router.navigate(['/login'], {replaceUrl: true})
        return false
      } else {
        return !isBoarded
      }
 
  }
}

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { environment as ENV } from './../../../../environments/environment.prod';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { GlobalSettings as GS } from 'src/app/global.settings';
import { PaymentService } from 'src/app/services/payment.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Customer } from 'src/app/models/user.model';
import { ScalapayCreate, ScalapayRequest } from 'src/app/models/payment.models';
import { PdaService } from 'src/app/services/pda.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

declare var Stripe
//declare var paypal

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, OnDestroy {

  stripe: any;
  @Input() total : number
  @Input() document_number: string;
  @Input() id: number;
  @Input() pod_pdr: string;

  success = false
  scalapaySuccess = false;
  utency

  constructor(
    private plt: Platform,
    private pda: PdaService,
    public paySvc: PaymentService,
    public modalController: ModalController,
    private toast: ToastService,
    public api: ApiService,
    public auth: AuthService,
    private alertCtrl: AlertController,
    private iab: InAppBrowser,
    private translate: TranslateService
  ) {
    //this.stripe = Stripe(ENV.stripe);
   }




  ngOnInit() {
    this.stripe = Stripe(ENV.stripe);
    if (!ENV.production) {
      console.log('public key stripe',ENV.stripe);
    }
    let utency = JSON.parse(localStorage.getItem(this.pod_pdr.includes('IT') ? 'light': 'gas'))
    utency = utency.filter(({pd}) => pd == this.pod_pdr);
    utency =  {...utency[0]};
    if(!utency.userCap ) {
      this.pda.findPdas((this.pod_pdr.includes('IT') ? GS.store.light : GS.store.gas) ,this.api.chartDate)
    }
  }
  ionViewDidEnter() {
  }
  payWithCard() {
    this.stripeSetUp()
  }

  // paypalSetup() {
  //   let payPalConfig = {
  //     env: 'sandbox',
  //     client: {
  //       sandbox: 'PG8L8U2DU8H2YSFK',
  //     },
  //     commit: false,
  //     style: {
  //       color: 'white',
  //       layout: 'horizontal',
  //       size: 'small',
  //       height: 50
  //     },
  //     createOrder: (data, actions)=> {
  //       return actions.order.create({
  //         purchase_units: [{
  //           amount: {
  //             value: this.total,
  //             currency: 'EUR'
  //           }
  //         }]
  //       });
  //     },
  //     // Finalize the transaction
  //     onApprove: (data, actions) => {
  //       return actions.order.capture().then((details) => {
            
  //         if (!ENV.production) console.log(details);
            
  //         if (details.status == 'COMPLETED') {
              
  //           this.success = true
  //           const bill = {id: this.document_number, paid: true}
  //           this.api.getFromStorage(GS.store.paidBill).then((res)=> {
  //             if (res) {
  //               res.push(bill)
  //               this.api.setToStorage(GS.store.paidBill,res )
  //             } else {
  //               this.api.setToStorage(GS.store.paidBill,[bill] )
  //             }
  //           })                
  //         }else{
  //           this.toast.present('Payment refused');
  //         }
  //         if (!ENV.production) console.log('Transaction completed by ' + details.payer.name.given_name + '!');
  //       })
  //       .catch(err => {
  //         if (!ENV.production) console.log('Paypal Error',err);
  //           this.toast.present('Payment refused');
  //       })
  //     },
  //     onError: (err) => { 
  //         // Show an error page here, when an error occurs
  //         if (!ENV.production) console.log('Paypal Error onError',err)
  //       this.toast.present('Payment refused');
  //         ////this.paymentError = true;
  //     }
  //   }
  //   paypal.Buttons(payPalConfig).render('#paypal-button-container');
  // }

  async stripeSetUp() {
    await this.paySvc.initialize(this.stripe, this.total, this.document_number)
  }
  dismiss(role = null) {
    this.modalController.dismiss({
      'dismissed': true,
      'role': role
    });
    this.paySvc.isReadyToPay = false;
  }
  async confirmStripePayment() {
    await this.paySvc.handleSubmit( this.stripe).then((res)=> {
      if (res['success']) {
        this.paySvc.stripeConfirmIntent(res['req']).subscribe(() => {
          this.success = true
          const bill = {id: this.document_number, paid: true}
          this.api.getFromStorage(GS.store.paidBill).then((res)=> {
            if (res) {
              res.push(bill)
              
              this.api.setToStorage(GS.store.paidBill,res )
            } else {
              this.api.setToStorage(GS.store.paidBill,[bill] )
            }
          })
          
          this.toast.dismissLoading();
          this.translate.get('SCALAPAY.success_toast').subscribe((msg) => {
            this.toast.present('success', true)
          })
          setTimeout(() => {
            this.backToBills()
          }, 10000);
        });
        

      } else { 
        this.toast.dismissLoading();
        this.toast.presentClose(res['msg'], false)
      }
    })
  }
  backToBills(paymentMode = 'stripe') {
    this.dismiss(paymentMode == 'stripe'? 'paid' : 'scalapay')
    //this.router.navigate([GS.pages.bills+ '/' + this.id] , {replaceUrl:true})
  }

  payWithScalaPay() {
    this.toast.loader('', '', 10000)

    const recapUser: Customer = JSON.parse(localStorage.getItem('recap'))
    let utency = JSON.parse(localStorage.getItem(this.pod_pdr.includes('IT') ? 'light': 'gas'));
    utency = utency.filter(({pd}) => pd == this.pod_pdr);
    utency =  {...utency[0]};

    const req :ScalapayRequest  = {
      ContractId : this.pod_pdr,
      Amount: this.total,
      InvoiceNumber: this.document_number,
      CustomerCode: recapUser.enerp_customer_code+"",
      Email : recapUser.email,
      Name: recapUser.firstname as string,
      Surname: recapUser.lastname as string
    }
    
    return this.paySvc.scalaPaySendRequest(req).subscribe((res)=> {
      if (!ENV.production) console.log(res);
      if (res['success']) {
        this.scalapaySuccess = true;
        const bill = {id: this.document_number, scalapay_request: true}
        this.api.getFromStorage(GS.store.paidBill).then((res)=> {
          if (res) {
            res.push(bill)
            this.api.setToStorage(GS.store.paidBill,res )
          } else {
            this.api.setToStorage(GS.store.paidBill,[bill])
          }
        })
      } else { 
        this.toast.presentClose(res['messages'], false)
      }
      this.toast.dismissLoading();
    }, err => {
      if (!ENV.production) console.log(err);
      this.translate.get('ERROR.payment').subscribe((msg) => {
        this.toast.presentClose(msg, false)
      })
      this.toast.dismissLoading();
    })
  }

  createOrderScalapay() {
    this.toast.loader('', '', 10000)

    const recapUser: Customer = JSON.parse(localStorage.getItem('recap'))
    let utency = JSON.parse(localStorage.getItem(this.pod_pdr.includes('IT') ? 'light': 'gas'));
    utency = utency.filter(({pd}) => pd == this.pod_pdr);
    utency =  {...utency[0]};

    const req: ScalapayCreate = {
      CustomerCode: recapUser.enerp_customer_code+"",
      Name: recapUser.firstname as string,
      Surname: recapUser.lastname as string,
      Countrycode: "it",
      Cap: utency.userCap+"",
      City:utency.userCity,
      Address: utency.adr,
      Quantity: "1",
      Amount: this.total.toFixed(2),
      Taxamount: "0.00",
      Currency:"EUR",
      Productname: "Fattura " + this.document_number,
      Productcategory: "fornitura",
      Sku: "Utenza " + utency.pd,
      Nrrate:"1",
      Frequencytype:"monthly",
      Email:recapUser.email
    }

    return this.paySvc.scalaPayCreateOrder(req).subscribe((res) => {
      const token = res['data'].token
      console.log(res['data'].token);
      this.toast.dismissLoading();
      // document.querySelector('#pay').removeAttribute('disabled')
      // document.querySelector('#pay').setAttribute('href' , res.url)
      if (this.plt.is("capacitor")) {
        const browser = this.iab.create(res['data']['url'], '_blank');
        browser.on("exit").subscribe((exit) => {
          this.confirmScalapayOrder(token)
          //this.captureOrderScalapay(token)
        })

      } else {
        const url = window.open(res['data']['url'], '_blank');
        const interval = setInterval(() => {
            if (url.closed) {
                clearInterval(interval);
                this.confirmScalapayOrder(token)
            }
        }, 2000); //
      }

    }, err => {
      if (!ENV.production) console.log(err);
      this.translate.get('ERROR.payment').subscribe((msg) => {
        this.toast.presentClose(msg, false)
      })
      this.toast.dismissLoading();

    })

  }

  statusOrderScalapay(token) {
    const req = { Token: token }
    return this.paySvc.scalaPayStatusOrder(req)
  }

  captureOrderScalapay(token) {
    
    const req = {
      Token: token,
      Amount: this.total.toFixed(2),
      Currency:"EUR"
   }
    return this.paySvc.scalaPayCaptureOrder(req)
  }

  confirmScalapayOrder(token) {
    this.statusOrderScalapay(token).subscribe((res) => {
      const data = JSON.parse(res['data'])
      console.log(JSON.parse(res['data']));
      if (data.status === "authorized") {
        this.captureOrderScalapay(data.token).subscribe((res) => {

          console.log(res);
          if (res['success']) {
            const bill = { id: this.document_number, paid: true }
            this.api.getFromStorage(GS.store.paidBill).then((res) => {
              if (res) {
                res.push(bill)

                this.api.setToStorage(GS.store.paidBill, res)
              } else {
                this.api.setToStorage(GS.store.paidBill, [bill])
              }
            })
            this.toast.dismissLoading();

            this.success = true;
            this.translate.get('SCALAPAY.success_toast').subscribe((msg) => {
              this.toast.present('success', true)
            })
            setTimeout(() => {
              this.backToBills()
            }, 10000);
          } else {
            if (!ENV.production) console.log(data.status);
            this.translate.get('SCALAPAY.notComplete').subscribe((msg) => {
              this.toast.presentClose(msg, false)
            })
          }
        }, err => {
          if (!ENV.production) console.log(err);
          this.translate.get('ERROR.payment').subscribe((msg) => {
            this.toast.presentClose(msg, false)
          })
          this.toast.dismissLoading();
        })
      } else {
        if (!ENV.production) console.log(data.status);
        this.translate.get('SCALAPAY.notComplete').subscribe((msg) => {
          this.toast.presentClose(msg, false)
        })
      }


    }, err => {
      if (!ENV.production) console.log(err);
      this.translate.get('ERROR.payment').subscribe((msg) => {
        this.toast.presentClose(msg, false)
      })
      this.toast.dismissLoading();

    })
  }

  async confirmScalapay(header = 'Scalapay', message = '', buttonText = 'ok', allowClose = true) {
    const buttons = []
    let no;
    this.translate.get('SCALAPAY.confirmModal').subscribe((res) => {
      buttonText = res.yes
      header = res.body
      no = res.no
    })
    if (buttonText != "") {
      buttons.push({
        text: buttonText,
        handler: () => {
          this.createOrderScalapay()
        }
      })

    }
    if (allowClose) {
      buttons.push({
        text: no,
        role: 'cancel'
      })

    }
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons,
      mode: 'ios',
      backdropDismiss: allowClose
    })
    await alert.present()
  }
  ngOnDestroy(): void {
    //console.log('destr');
  }
}
//declare function render(params: RenderParams): () => void;


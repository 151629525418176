import { ApiService } from 'src/app/services/api.service';
import { AfterViewInit, Component, ElementRef, Input, ViewChild, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { GlobalSettings as GS } from 'src/app/global.settings';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements AfterViewInit ,OnInit {
  GS: { months: string[]; fullMonths: string[]; kw: string; mc: string; pod: string; pdr: string; currency: string; light: string; gas: string; defaultImg: string; defaultIcon: string; defaultName: string; store: { pageNews: { bills: string; utilities: string; faq_details: string; faq_index: string; self_reading: string; settings: string; support: string; }; isGas: string; faq: string; boarded: string; photos: string; user: string; gas: string; light: string; news: string; slides: string; homeNews: string; offers: string; manuals: string; bills: string; lang: string; links: string; whatsapp: string; recap: string; paidBill: string; darkMode: string; lastSync: string; push: string; }; pages: { activation: string; boarding: string; bills: string; consumers: string; faq_detail: string; faq_page: string; get_code: string; home: string; login: string; news: string; profile: string; self: string; support: string; upgrade: string; }; email_pattern: string; prefix: string; whatsapp: string; mode: { ios: string; and: string; }; input_type: { text: string; password: string; }; welcome_board: { active: string; preStasus: string[]; waitStatus: string; errorStatus: string[]; closedStatus: string[]; }; APPSTORE: { ios: string; android: string; }; push: { expiryDays: number; fcmSenderID: string; pushwooshAppID: string; }; DEVMENU: string; RefreshTime: number; };
  
  constructor(
    public nav: NavController,
    public api: ApiService) { 
      this.GS = GS

  }
    
  iconReady = false;
  myIconName = GS.defaultIcon


  @Input() chartData = {
    adr: '',
    type: '',
    ko: '',
    id: '',
    shadd: '',
    status: ''
  };
  customData = {icon: 'a' , name: GS.defaultName}

  ngOnInit() {
 
    let pd = this.chartData['pd'].includes('IT')? GS.pod : GS.pdr
    this.customData.name =pd +' ' +  this.chartData['pd'];
    //this.api.getIcons().subscribe((data)=> {
      this.icons = this.api.icons
      this.api.getFromStorage(this.chartData['pd']).then((val)=> {
        
        if (val) {
          this.api.personal[this.chartData['pd']] = val;
          
          this.customData.icon = val.icon
          val.name.trim() == ''? null : this.customData.name = val.name
          this.api.personal[this.chartData['pd']]['name'].trim() == ''? 
          this.api.personal[this.chartData['pd']]['name'] = this.chartData['pd'] : 
          this.api.personal[this.chartData['pd']]['name'] = val.name
        } else {
          this.api.personal[this.chartData['pd']] = {icon: 'a' , name: this.chartData['pd']}
          this.api.setToStorage(this.chartData['pd'], {icon: 'a' , name: this.chartData['pd']})
        }


          for (const key in this.icons) {
            if (Object.prototype.hasOwnProperty.call(this.icons, key)) {
              const element = this.icons[key];
              
              let a = element.filter((el)=> el.val == this.customData.icon )
              if (a.length > 0) { 
                a = a.map(({name}) => {
                  this.myIconName = name
                  this.api.personal[this.chartData['pd']]['icon'] = name
                  this.iconReady = true;
                })
                return 
              }
            }
          } 
          
      })
   

  }
  @ViewChild('unityChart') private unityChart: ElementRef;
  @ViewChild('currencyChart') private currencyChart: ElementRef;

  icons;
  gradient: any;
  currencyVarChart: any;
  unityVarChart: any;
  unit: boolean = true;
  showChart: boolean = true;

  ngAfterViewInit() {
    //console.log(this.chartData);
    
      this.setGradient();
      this.getCurrencyChart();
      this.getUnityChart();
        
  }
  setGradient() {
    this.gradient = this.currencyChart.nativeElement.getContext("2d").createLinearGradient(0, 0, 0, 150);
    this.gradient.addColorStop(1, "#3293C4");
    this.gradient.addColorStop(0.5, "#3aa1aa");
    this.gradient.addColorStop(0, "#47B783");
  }

  getCurrencyChart() {
    let eur = this.chartData['eur']
    
    // Now we need to supply a Chart element reference with an object that defines the type of chart we want to use, and the type of data we want to display.
    this.currencyVarChart = new Chart(this.currencyChart.nativeElement, {
      type: 'bar',
      data: {
        labels: this.chartData['labels'],
        datasets: [{
          barThickness: 30,
          label: GS.currency,
          data: eur,
          backgroundColor: [
            this.gradient
          ],
          borderColor: [
            this.gradient
          ],
          borderRadius: 10,
          borderWidth: 0,
          borderSkipped: false
        }]
      },
      options: {
        animations : {

          numbers: { duration: 0 },
          colors: {
            type: "color",
            duration: 1000,
            from: "transparent",
          }
        },
        scales: {

          yAxis: {
            display: false,
            type: 'linear',
            position: 'right',
            grid: {
              color:'red',
              display: false
            } // `axis` is determined by the position as `'y'`
          },
          xAxis: {
            grid: {
              borderWidth: 0,
              color: '#FF0000',

              display: false
            } //
          }
        },
        plugins: {
          tooltip: {
            backgroundColor: 'rgba(0, 59, 77, 1)',
            
            
            displayColors: false,

            callbacks: {
              label : function(context) {
                let label = context.parsed.y
                return (+label).toFixed(2).replace('.', ',')  +' '+ context.dataset.label
              },
              title: function(context) {
                return ''
              },
              labelTextColor: function(context) {
                  return '#FFF';
              }
            }
          },
          title: {
              display: false,
              
          },
          legend: {
            display: false,
            labels: {
                color: 'rgb(255, 99, 132)'
            }
        }
      }
      }
    });
  }
  getUnityChart() {
    let kw = this.chartData['kw']

    this.unityVarChart = new Chart(this.unityChart.nativeElement, {
      type: 'bar',
      data: {
        labels: this.chartData['labels'],
        datasets: [{
          barThickness: 30,
          label: this.chartData['type'],
          data: kw,
          backgroundColor: [
            this.gradient
          ],
          borderColor: [
            this.gradient
          ],
          borderRadius: 10,
          borderWidth: 0,
          borderSkipped: false
        }]
      },
      options: {
        animations : {
          numbers: { duration: 0 },
          colors: {
            type: "color",
            duration: 1000,
            from: "transparent",
          }
        },
        scales: {

          yAxis: {
            display: false,
            type: 'linear',
            position: 'right',
            grid: {
              color:'red',
              display: false
            } // `axis` is determined by the position as `'y'`
          },
          xAxis: {
            grid: {
              borderWidth: 0,

              color: '#FF0000',
              display: false
            },
            
          },
        },
        plugins: {
          tooltip: {
            backgroundColor: 'rgba(0, 59, 77, 1)',
            
            
            displayColors: false,

            callbacks: {
              label : function(context) {
                let label = context.parsed.y
                return label +' '+ context.dataset.label
              },
              title: function(context) {
                return ''
              },
              labelTextColor: function(context) {
                  return '#FFF';
              }
            }
          },
          title: {
              display: false,
              
          },
          legend: {
            display: false,
            labels: {
                color: 'rgb(255, 99, 132)'
            }
        }
      }
      }
    });

  }

  changeUnit(ev) {
    this.showChart = !this.showChart
  }
  goToDetail(pageUrl: any, params: any) {
    this.api.setToStorage(GS.store.bills , this.chartData)
    this.nav.navigateForward(pageUrl, { state: params });
  }



}

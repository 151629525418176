export const GlobalSettings = {

    months : ['','Gen','Feb','Mar','Apr','Mag','Giu','Lug','Ago','Set','Ott','Nov','Dic'],
    fullMonths : ['','Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'],
    kw: 'kWh',
    mc: 'Smc',
    pod: 'Pod',
    pdr: 'Pdr',
    currency: 'Eur',
    light: 'light',
    gas: 'gas',
    defaultImg: '/assets/icon/profilo.svg',
    defaultIcon: 'house',
    defaultName: 'Utenza',
    store: {
        pageNews: {
            bills: '',
            utilities: '',
            faq_details: '',
            faq_index: '',
            self_reading: '',
            settings: '',
            support: ''
        },
        isGas: 'isGas',
        faq: 'faq',
        boarded: 'boarded',
        photos: 'photo',
        user: 'user',
        gas: 'gas',
        light: 'light',
        news: 'news',
        slides: 'slides',
        homeNews: 'homeNews',
        offers: 'offers',
        manuals: 'manuals',
        bills: 'bills',
        lang: 'lang',
        links: 'links',
        whatsapp: 'whatsapp',
        recap: 'recap',
        paidBill: 'paidBill',
        darkMode: 'darkMode',
        lastSync: "lastSync",
        push: "push"
    },
    pages: {
        activation: '/activation',
        boarding: '/boarding',
        bills: '/bill',
        consumers: '/main/consumers',
        faq_detail: 'main/faq-detail',
        faq_page: '/main/faq',
        get_code : '/code',
        home : '/main/home',
        login: '/login',
        news: '/main/news',
        profile: '/main/profile',
        offline: '/offline',
        self: '/main/self-check',
        support: '/main/support',
        upgrade: '/upgrade',
        scalaSuccess: "/scalapaysuccess",
        scalaFail: "/scalapayfail"
    },
    email_pattern: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
    prefix: '39',
    whatsapp: 'https://api.whatsapp.com/send?phone=',
    mode: {
        ios: 'ios',
        and: 'md'
    },
    input_type: {
        text: 'text',
        password: 'password'
    },
    welcome_board: {
        active: 'activated',
        preStasus : ['draft', 'awaiting_validation','awaiting_customer','precheck_ko','incomplete' ],
        waitStatus : 'validated',
        errorStatus : ['error','cancelled','switching_deny','afterthought'],
        closedStatus : ['closed', 'expired', 'disabled']
    },
    APPSTORE: {
        ios: "https://apps.apple.com/us/app/id1626800797",
        android: "it.ajoenergia.app"
    },
    push: {
        token: '',
        expiryDays: 7,
        fcmSenderID:'922575508744',
        pushwooshAppID:'8B313-62559'
    },    
    DEVMENU: "putifigari",
    DEFAULT_LANG: 'IT',
    RefreshTime: 1000 /*m.sec*/
                *60 /*sec*/    
                *60 /*min*/     
                *24  /*ore*/
}
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSettings as GS } from 'src/app/global.settings';
@Component({
  selector: 'app-boarder',
  templateUrl: './boarder.component.html',
  styleUrls: ['./boarder.component.scss'],
})
export class BoarderComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public router: Router,
    public api: ApiService
  ) { }

  ngOnInit() {
    this.api.setToStorage('boarded' , true)

  }
  @Input() data = {
    title: '',
    body: '',
    image: ''
  };

  boardingSeen() {
    this.auth.boardedSeen = true;
   // this.api.setToStorage('boarded' , true)
    this.router.navigate([GS.pages.activation], {replaceUrl: true})
  }


}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'comp-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsMainComponent implements OnInit {

  constructor() { }
  @Input() news 
  @Input() isOffers;

  ngOnInit() {}

}

import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';
import { ApiService } from './api.service';
import { Platform } from '@ionic/angular';
import { environment as ENV } from './../../environments/environment';
import { GlobalSettings as GS } from './../global.settings';
import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders } from '@angular/common/http';
//import { Events } from "ionic-angular";
import { Observable, ReplaySubject } from "rxjs";


//import { Diagnostic } from "@ionic-native/diagnostic";
//import { SettingsStorage } from "../local-data/settings.storage";

import { PWPushData, PWTag } from "../models/pushwoosh.models";
//import { OpenNativeSettings } from "@ionic-native/open-native-settings";
//import { PushWooshService } from "./pushwoosh.service";
import { catchError, first } from "rxjs/operators";
import Pushwoosh from 'pushwoosh-cordova-plugin';
import { Router } from '@angular/router';
//import { MyEvent } from "../../common/MyEvents";



export interface PushInfo {
  lastPush: PWPushData
  timestampISO: string,
}

@Injectable({
  providedIn: 'root'
})
export class PushService {
  
  public pushNotifier: ReplaySubject<PushInfo> = new ReplaySubject<PushInfo>(1);
  public tagsNotifier: ReplaySubject<any> = new ReplaySubject<any>(1);

  public allTags = {};
  pushNotification;
  pushwooshHWID: string;
  pushwooshToken: string;
  private isPluginReady: boolean = false;
  private syncTagsOnInit: boolean = false;

  notification: number = 0;
  constructor
  (
    private http: HttpClient,
    private plt: Platform,
    private api: ApiService,
    private toast: ToastService,
    private translate: TranslateService,
    public router: Router,

    //private events: Events
    )
  {
    
    

  }
  

  /***
   * Inizializzazione SDK ed operazioni preliminari.
   *
   * @param syncTags
   */
  async initOnDeviceReady(syncTags: boolean)
  {
    this.syncTagsOnInit = syncTags;
    
    try {
      this.pushNotification =  (<any>window).plugins.pushNotification;
      this.isPluginReady = true;

      this.api.getFromStorage(GS.store.push).then((res)=> {

        if (res === true || res === null) {
          this.initSDK();
          this.api.stored.push = true
        } else {
          this.api.stored.push = false
        }
      })

    } catch (e) {
      this.isPluginReady = false;
       if (!ENV.production)
        console.error("[Pushwoosh] ERROR: Pushwoosh non inizializzato");
    }
  }

  async unregister() {
    return await this.pushNotification.unregisterDevice((success) => {
      this.api.setToStorage(GS.store.push, false ).then(()=> {
        this.api.stored.push = false
      })
    },(fail) => {
      this.translate.get('ERROR.push_off').subscribe((err)=> {
        this.toast.present(err, false);
        this.api.stored.push = true

      })

    }
    )
  }


  initSDK() {
    

    document.addEventListener('push-notification',
      (event) => {

        const push: PWPushData = (<any>event).notification as PWPushData;
        this.notifyPush(push);
        if (this.notification > 0) {
          this.notification--
        }

        if (!ENV.production) {
          console.info("[Pushwoosh] messaggio ** cliccato **: ", push);
          this.toast.presentClose(JSON.stringify(push))
        }
          let page = this.plt.is("android") ? push.android['l'] : push.ios['l'];
          page = page.split('scheme://it.ajoenergia.app/')[1];
          if (page) {
            this.router.navigate([page], { replaceUrl: true }); 
          }
      }
    );

    //
    // Push RICEVUTA in notification center
    //
    // Se app attiva, l'evento scatta alla ricezione (senza nessun click)
    // Se app in background, l'evento scatta solo al click insieme all'evento 'push-notification'
    //

    document.addEventListener('push-receive',
      (event) => {

        const push: PWPushData = (<any>event).notification as PWPushData;
        this.notifyPush(push);
        this.notification ++;
        if (!ENV.production)
          console.info('[Pushwoosh] messaggio ** ricevuto **:', push);

      }
    );


    //
    // Init Pushwoosh
    //

    this.pushNotification.onDeviceReady({
      projectid:  GS.push.fcmSenderID,
      appid: GS.push.pushwooshAppID,
      serviceName: ""
    })

    //
    // Device registrato
    //
    
    this.pushNotification.registerDevice(
      //OK
      (status) => {
         if (!ENV.production) 
        console.log("[Pushwoosh] device registrato con token: " + status.pushToken);

        //Token             
        this.api.stored.push = true
        this.api.setToStorage(GS.store.push, true )
        GS.push.token = status.pushToken
        //this.onPushwooshInitialized.bind(this)();
      },

      //Errore
      (error) => {
        //console.log(error);
        
        this.isPluginReady = false;
        this.translate.get('ERROR.push_on').subscribe((res)=> {
          this.toast.present(res, false)
          this.api.stored.push = false
        })

        if (!ENV.production) {
          console.warn(JSON.stringify(['[Pushwoosh] registrazione device fallita', error]));
        }
      }
    );
  }

  /***
   * Attività post-inizializzazione
   *
   * - sincronizzazione delle preferenze di ricezione
   * - reset del badge di notifica
   */
  onPushwooshInitialized()
  {

    //HWID per l'utilizzo delle API
    this.pushNotification.getPushwooshHWID((token) => {
      this.pushwooshHWID = token;

      if (this.syncTagsOnInit === true)
        this.syncTags();

      //this.events.publish(MyEvent.PUSH_READY);
    });

  }

  resetAppBagde()
  {
    this.pushNotification.setApplicationIconBadgeNumber(1);
    this.pushNotification.setApplicationIconBadgeNumber(0);
  }


  /***
   * Sincronizza le preferenze
   *
   */
  syncTags()
  {
    this.getTags(this.pushwooshHWID)
      .pipe(
        first()
      )
      .subscribe(resp => {

        this.allTags = {};
        if (resp['status_code'] != 200) {

          if (!ENV.production)
            console.log('[Pushwoosh] Recupero Tags da remoto - status_code non 200:', resp);

        } else {
          const response = resp['response'];
          this.allTags = response.hasOwnProperty('result') ? response['result'] : {};
        }

        this.tagsNotifier.next({...this.allTags});

        if (!ENV.production)
          console.log('[Pushwoosh] Tags da remoto:', this.allTags)
      })
  }

  setTags(keyVal: { key: PWTag, val: any }[])
  {

    const onTagsUpdateFn = (allTags, isOk: boolean) => {

      if (!ENV.production) {

        if (!isOk) {
          console.log('[Pushwoosh] Update Tags remoti fallito - i valori sono stati ripristinati:', allTags);
        } else {
          console.log('[Pushwoosh] Tags aggiornati:', allTags);
        }

      }

      //Aggiornamento/ripristino valori
      this.tagsNotifier.next(allTags);
    };


    if (!this.isPluginReady) {
      if (!ENV.production)
        console.log('[Pushwoosh] setTags ** plugin in non attivo ** - (pluginReady %s | isDevice %s | tags: %o):', this.isPluginReady, this.plt.is("hybrid"), keyVal);

      if (!this.plt.is("hybrid")) {
        keyVal.forEach(item => {
          this.allTags[item.key] = item.val;
        });

        onTagsUpdateFn({...this.allTags}, true);
      }

      return;
    }


    this.setTagx(keyVal, this.pushwooshHWID)
      .pipe(
        first(),
        catchError(err => Observable.throw(new Error('SetTag FALLITO')))
      )
      .subscribe(
        (resp) => {


          if (resp['status_code'] != 200) {

            // ERROR

            onTagsUpdateFn({...this.allTags}, false);

          } else {

            // OK - aggiornamento tags

            keyVal.forEach(item => {
              this.allTags[item.key] = item.val;
            });

            onTagsUpdateFn({...this.allTags}, true);

          }

        },
        err => onTagsUpdateFn({...this.allTags}, false))

  }


  /***
   * Dispatcher ultima push ricevuta/cliccata
   *
   * @param p
   */
  notifyPush(p: PWPushData)
  {
    this.pushNotifier.next({
      lastPush: p,
      timestampISO: new Date().toISOString()
    })
  }



  /***
   * Stato dell'abilitazione nativa delle push sul device
   *
   * @returns {Promise<boolean>}
   *
   */
  isPushEnabledOnDevice()
  {
    if (!this.plt.is("hybrid")) {
      //Device
      //return this.diagnostic.isRemoteNotificationsEnabled();
    } else {
      //Desktop
      return new Promise((res) => res(true));
    }
  }

  /****
   * Switching alle impostazioni native per le push dell'app
   *
   * @returns {Promise<any>}
   *
   */
  openNativePushSettings()
  {
    // return this.openNativeSettings.open('notification_id')
    //   .catch(err => console.warn("[openNativeSettings] open settings fallito", err));
  }

  getTags(hwID: string): Observable<any>
  {


    return this.http.post( ENV.api.BASE_URL_ID  , this.createGetTagsApiReq(hwID));
  }

  /**
   * Recupero dei tags per il dispositivo
   *
   * @param keyVal
   * @param  hwID hardwareID assegnato con registerDevice
   */
  setTagx(keyVal: { key: PWTag, val: any }[], hwID: string): Observable<any>
  {

    // const _opt = Object.assign(<ApiOptions>{}, {baseUrl: this.ENV.pushApi.BASE_URL});
    // const setupObj: ApiOptions = this.parseOptions(_opt);

    return this.http.post(ENV.pushApi.setTags, this.createSetTagsApiReq(hwID, keyVal));
  }



  //region ##|Api request

  /***
   * request per recupero Tags
   *
   * @param hwID hardwareID assegnato con registerDevice
   */
  private createGetTagsApiReq(hwID: string): any
  {
    return {
      'request': {
        'application': GS.push.pushwooshAppID,
        'hwid': hwID
      }
    };
  }

  /***
   * request per recupero Tags
   *
   * @param hwID hardwareID assegnato con registerDevice
   * @param keyVal
   */
  private createSetTagsApiReq(hwID: string, keyVal: { key: PWTag, val: any }[]): any
  {
    const tags = {};
    keyVal.forEach(keyVal => {
      tags[keyVal.key] = keyVal.val;
    });

    return {
      'request': {
        'application': GS.push.pushwooshAppID,
        'hwid': hwID,
        'tags': tags
      }
    };
  }




openUI() {
  this.pushNotification.presentInboxUI();
}




}

import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-homeslider',
  templateUrl: './homeslider.component.html',
  styleUrls: ['./homeslider.component.scss'],
})
export class HomesliderComponent implements OnInit {

  constructor() { }
  @Input() slide;

  ngOnInit() {
    
  }


}

import { Contacts } from './../../../models/support.model';
import { Component, Input, OnInit } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { TicketComponent } from '../../modals/ticket/ticket.component';
import { Router } from '@angular/router';
import { GlobalSettings as GS } from 'src/app/global.settings';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
})
export class LinksComponent implements OnInit {

  constructor(
    public modalController : ModalController,
    public routerOutlet: IonRouterOutlet,
    public router: Router ) { }

  ngOnInit() {
  }
  @Input() contact: Contacts;

  async presentModal() {
    const modal = await this.modalController.create({
      component: TicketComponent ,
      presentingElement: this.routerOutlet.nativeEl ,
      mode: 'ios',
      componentProps: {
        email: this.contact.email
      }
    });
    return await modal.present();
  }
  goToFaq() {
    this.router.navigate([GS.pages.faq_page])
  }


}

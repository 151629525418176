import { Network } from '@capacitor/network/dist/esm';
import { ToastService } from 'src/app/services/toast.service';
import { StoreService } from './services/store.service';
import { GlobalSettings as GS } from './global.settings';
import { PhotoService } from './services/photo.service';
import { PdaService } from 'src/app/services/pda.service';
import {  Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Platform, MenuController} from '@ionic/angular';
import { Keyboard } from '@capacitor/keyboard';
import { PaymentService } from './services/payment.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  user = {name: "", lastname: "", email: ""}
  networkIsConnected: boolean = false;
  public appPages  = [
    // { title: 'light' , url: '/main/consumers/luce', icon: 'light-m.svg' },
    // { title: 'gas' , url: '/main/consumers/gas', icon: 'gas-m.svg' },
    // { title: 'self', url: GS.pages.self, icon: 'eye-m.svg' },
    // { title: 'sardinia', url: '/main/home', icon: 'leaf-m.svg' },
    // { title: 'friend', url: '/main/home', icon: 'friend-m.svg' },
    { title: 'support', url: GS.pages.support, icon: 'support-m.svg' },
    { title: 'news', url: GS.pages.news, icon: 'news-m.svg' },
    { title: 'settings', url: GS.pages.profile, icon: 'settings-m.svg' },
  ];
  constructor ( 
    public photoSvc: PhotoService,
    public pda : PdaService,
    public api: ApiService, 
    private plt : Platform,
    public auth : AuthService,
    public router: Router,
    private mc: MenuController,
    public paymentSvc: PaymentService,
    public storeSvc: StoreService ,
    private toast: ToastService ) {
      
      this.plt.ready().then(()=> {
        if (window.location.href.includes('scalapaysuccess'))  this.auth.staticpage = '/scalapaysuccess';
        if (window.location.href.includes('scalapayfail'))  this.auth.staticpage = '/scalapayfail';
        if (this.plt.is("ios") && this.plt.is("hybrid") ) {
          Keyboard.setScroll({isDisabled: true})
        }
        this.initializeApp()
      })
    }
      
  async ngOnInit() {
    if (!this.plt.is("hybrid")) {
      this.toast.loader('MyAjò')
    }
  }
  
  async ngAfterViewInit(){
    await this.photoSvc.loadSaved()
    setTimeout(() => {
      if (!this.plt.is('capacitor')) this.toast.dismissLoading()
    }, 2000);

  }

  async initializeApp() {
    const network = await Network.getStatus()
    this.networkIsConnected = network.connected;
    if (this.networkIsConnected) {
      await this.storeSvc.initializeApp();
    } else {
      this.router.navigate([GS.pages.offline] , {replaceUrl: true})
      this.networkIsConnected = false
    }

    await Network.addListener('networkStatusChange', status => {
      if (status.connected && this.networkIsConnected == false) {
        this.networkIsConnected = true
        this.storeSvc.initializeApp();
      } else if (!status.connected) {
        this.networkIsConnected = false
        this.router.navigate([GS.pages.offline] , {replaceUrl: true})
      }
    });
   
     //await this.storeSvc.initializeApp();
  }


  goToProfile() {
    this.router.navigate([GS.pages.profile], { replaceUrl: true });          
    this.mc.close()
  }
}

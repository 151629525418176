import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSettings as GS } from 'src/app/global.settings';

@Component({
  selector: 'app-headback',
  templateUrl: './headback.component.html',
  styleUrls: ['./headback.component.scss'],
})
export class HeadbackComponent implements OnInit {

  constructor(private router : Router
    ) { }

  ngOnInit() {
   
  }
  @Input() back = true

  goBack() {
    this.router.navigate([GS.pages.activation], { replaceUrl: true }) //, { replaceUrl: true }
  }


}

import { PaymentsComponent } from './../../modals/payments/payments.component';
import { IonRouterOutlet, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './../../../services/api.service';
import { FileService } from './../../../services/file.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ToastService } from 'src/app/services/toast.service';
import { PdaService } from './../../../services/pda.service';
import { Bill, InvoiceDownload } from './../../../models/pda.model';
import { Component, Input, OnInit } from '@angular/core';
import { GlobalSettings as GS } from 'src/app/global.settings';
import { EnergyType } from './../../../models/pda.model';
import { PaymentService } from 'src/app/services/payment.service';
@Component({
  selector: 'app-bill-detail',
  templateUrl: './bill-detail.component.html',
  styleUrls: ['./bill-detail.component.scss'],
})
export class BillDetailComponent implements OnInit {
  finalAmount: number;

  constructor(
    private translate: TranslateService,
    private pda: PdaService,
    public toast: ToastService,
    public iab: InAppBrowser,
    public fileSvc: FileService,
    public api: ApiService,
    private paySvc: PaymentService,
    private plt: Platform,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet, 
  ) {
    this.gs = GS
    this.energyType = EnergyType
   }

  ngOnInit() {
    this.translate.get('MAIN.months').subscribe((res=> {
      this.months = res
    }))
   }
  months = GS.months
  gs
  energyType;
  @Input() invoice : Bill;
  @Input() pod : string;

  expireDate() {
    if (this.invoice.invoice_expire_date) {
      let expireDate = this.invoice.invoice_expire_date.split('-')
      return `${expireDate[2]} ${this.months[+expireDate[1]]} ${expireDate[0]}`
      
    } else {
      return '-'
    }

  }
  releaseDate() {

    let releaseDate = this.invoice.invoice_release_date.split('-')
    return `${releaseDate[2]} ${this.months[+releaseDate[1]]} ${releaseDate[0]}`
  }

  downloadInvoice(id , detail) {
    this.pda.downloadInvoice(id, detail).subscribe((res: InvoiceDownload)=> {
      //console.log(res)
      if(res.result?.error) {
        this.translate.get('ERROR.' + res.result.errorCode).subscribe((res)=> {
          this.toast.present(res, false)
        })
        return
      }
      let base64 = res.result.invoice[0].file_content
      let name = res.result.invoice[0].file_name
      if (this.plt.is("hybrid")) {
        this.downloadPdf(base64, name)
      } else {
        this.downloadWebPdf(base64,name);
      }
    })
  }

  downloadWebPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }


  downloadPdf(base64String, fileName) {
    this.fileSvc.saveAndOpenPdf(base64String, fileName)
  }

  async presentModal(invoice: Bill) {
    const modal = await this.modalController.create({
      component: PaymentsComponent,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: {
        total: invoice.total,
        document_number: invoice.document_number,
        pod_pdr: this.pod,
        id: invoice.snap
      },
      mode: 'ios'
    });
     await modal.present();
     const {data} = await modal.onDidDismiss()
     data.role === 'paid'?  this.invoice.is_paid = true : null
     data.role === 'scalapay'?  this.invoice.scalapay_request = true : null
  }

  parseComma(n) {
    return n?.toFixed(2).replace('.', ',')    
  }















}

import { PushService } from './../../../services/push.service';
import { PhotoService } from './../../../services/photo.service';
import { ApiService } from './../../../services/api.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';import { Platform } from '@ionic/angular';
import { GlobalSettings as GS } from 'src/app/global.settings';
import { Location } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header-full.component.html',
  styleUrls: ['./header-full.component.scss'],
})
export class HeaderFullComponent implements OnInit {

  constructor(private router : Router,
    public ps: PhotoService,
    public api : ApiService,
    public ds: DomSanitizer,
    public push: PushService,
    private location: Location,
    private plt: Platform) { }

  isMobile : boolean;

  async ngOnInit() {
    this.isMobile = this.plt.is("hybrid");
    this.ps.isReady = false
    await this.ps.loadSaved().then(()=> {
      this.ps.isReady = true
    })
   
  }

  @Input() mode = 'full'
  @Input() isCenter = false

  goToProfile() {
    this.router.navigate([GS.pages.profile]) //, { replaceUrl: true }
  }

  openPushPanel() {
    //this.push.openUI()
  }

  goBack() {
    this.location.back()
  }

}

import { ToastService } from './../../../services/toast.service';
import { ApiService } from 'src/app/services/api.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
export interface TicketResponse {
  messages: string[]
  responseStatus: string
  success: boolean
}
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss'],
})

export class ContactusComponent implements OnInit {
  isSubmit: boolean = false;
  steps = 0;
  contactForm : FormGroup
  attachmentFile;
  pdfName: any;

  constructor(
    private toast: ToastService,
    public modalController: ModalController,
    public api: ApiService
  ) { 
    this.inputControl()
  }
  inputControl() {
    this.contactForm = new FormGroup({
      text: new FormControl('', Validators.required),
      option: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      user: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ]))
    })  

  }
  ngOnInit() {}

  dismiss() {
    this.steps = 0
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  contactSubmit() {
    this.isSubmit = true
    if (this.contactForm.valid ) {
      let req = {
        Name: this.contactForm.value.user,
        Message: this.contactForm.value.text,
        PhoneNumber: this.contactForm.value.phone,
        Email: this.contactForm.value.email,
        SupplyAddress: this.contactForm.value.address,
        ContactType: this.contactForm.value.option,
        Modulo: this.attachmentFile

      }
      this.toast.loader()

      this.api.sendTicket(req).subscribe((res: TicketResponse) => {
        this.toast.dismissLoading();
        if (res.success){ 
          this.steps = 1 
        }
      }, err => {
        this.toast.dismissLoading();
      })
    }

  }
  checkValidate( submit , ctr) {
    if (submit) {
      return this.contactForm.hasError('required' , ctr) || this.contactForm.hasError('pattern' , ctr)
    }
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }

  onSelectFile(event) {
  
    if (event.target.files && event.target.files[0]) {
      let newInstance = this.getFileReader();
      newInstance.readAsDataURL(event.target.files[0]);
      newInstance.onload = (imgsrc) => {
        let url = (imgsrc.target as FileReader).result;
        this.attachmentFile = url;
      }
      let fileList: FileList = event.target.files;  
      let file: any = fileList[0];
      this.pdfName = file.name;
      
    }
  }

}

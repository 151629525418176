import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { GlobalSettings as GS } from 'src/app/global.settings';
@Component({
  selector: 'req-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})

export class ReqSuccessComponent implements OnInit {
  
  constructor(public mc: MenuController ,public router: Router) { }
  @Output() step = new EventEmitter();
  ngOnInit() {
    this.mc.swipeGesture(false)

  }
  goToActivation() {
    this.router.navigate([GS.pages.activation], {replaceUrl: true})
    this.step.emit(0)
  }

}

import { GlobalSettings as GS } from './../../../global.settings';
import { ToastService } from './../../../services/toast.service';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-forgotpsw',
  templateUrl: './forgotpsw.component.html',
  styleUrls: ['./forgotpsw.component.scss'],
})
export class ForgotpswComponent implements OnInit {
  isSuccess = false;
  isSubmit: boolean = false;
  recoveryForm : FormGroup

  constructor(
    public modalController: ModalController,
    public auth : AuthService,
    public toast: ToastService
  ) { 
    this.inputControl()
  }
  inputControl() {
    this.recoveryForm = new FormGroup({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(GS.email_pattern)
      ]))
    })

  }
  ngOnInit() {}
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  /**
   * Manda un link alla mail dell'utente con cui ripristinare la password
   */
  recoverySubmit() {
    this.isSubmit = true
    if (this.recoveryForm.valid ) {
      this.toast.loader()
    
      this.auth.PasswordRecover(this.recoveryForm.value.email).then(()=> {
        this.toast.dismissLoading()
        this.isSuccess = true;
        setTimeout(() => {
          this.dismiss();
        }, 6000);
      }).catch((error) => {
        this.toast.dismissLoading()
        this.toast.present(error, false)
      });
    }
  }
  checkValidate() {
    if (this.isSubmit) {
      return this.recoveryForm.hasError('required' , 'email') || this.recoveryForm.hasError('pattern' , 'email')
    }
  }
}

import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';
import { ApiService } from './api.service';
import { PdaRequest, InvoiceReq, DocsType, InvoiceResp, PdaResponse, EnergyType } from './../models/pda.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as ENV } from 'src/environments/environment';
import { map, retry, switchMap, tap, toArray } from 'rxjs/operators';
import { GlobalSettings as GS } from '../global.settings';
import * as moment from 'moment';
import { of } from 'rxjs';
import { ContactusComponent } from '../components/modals/contactus/contactus.component';
import {  ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PdaService {
 
  constructor(private http: HttpClient,
    public modalController: ModalController,
    public translate: TranslateService,
    public api: ApiService,
    public toast : ToastService) {
      
   
   }
  header;
  
  months: any
  lux = []
  gas = []
  chartReady = {light: true, gas: true}
  isGas = false

/**
 * restituisce l'elenco delle utenze gas o elettrica
 * @param req Tipo PdaRequest "gas" || "ee"
 */
  getPdaList(req: PdaRequest) {
    return this.http.post(ENV.api.BASE_URL_IY + ENV.api.endpoint.pdas , req, {headers: this.header})
  }

  // getPda(id: string) {
  //   return this.http.post(ENV.api.BASE_URL_IY + ENV.api.endpoint.pdas + '/' + id, {headers: this.header})
  // }
  // getPdaDetails(id: string) {
  //   return this.http.post(ENV.api.BASE_URL_IY + ENV.api.endpoint.pdas + '/' + id + ENV.api.endpoint.readings, {headers: this.header})
  // }
  getInvoices(req: InvoiceReq, id: string) {
    return this.http.post(ENV.api.BASE_URL_IY + ENV.api.endpoint.pdas + '/' + id + ENV.api.endpoint.invoices , req, {headers: this.header})
  }
  downloadInvoice(id: number, docs: DocsType) {
    return this.http.post(ENV.api.BASE_URL_IY + ENV.api.endpoint.invoice + '/' + id + ENV.api.endpoint.download +'/'+ docs, {headers: this.header})
  }

  /**
   * Cerca le utenze elettriche o gas abbinate all'utente 
   * in un  determinato periodo di tempo e restituisce il grafico 
   * @param podType PdaRequest "gas" || "ee"
   * @param chartDate  oggetto {from: "" , to: ""}
   */

  findPdas(podType , chartDate  ) {
    this.chartReady[podType] = false

    let req: PdaRequest = { pda_type: EnergyType[podType] }
    let userInfo;

    this.getPdaList(req).pipe (
      tap((res: PdaResponse) => { 
        if (res['error']) {
          this.toast.present(res['error'].message, false)
          this.chartReady[podType] = true

          return of({
            jsonrpc: 2.0,
            id: null,
            result: {
              success: false,
              pdas: []
            }
          })
        }
        //console.log(res);
        if ( res.result?.success == false  ) {
          if (res.result?.errorCode == 2130) {
            this.translate.get('ERROR.' + res.result?.errorCode ).subscribe((res)=> {
              this.toast.presentClose(res, false)
            })
            
          } else if (res.result?.errorCode == 1010) {
            throw new Error("");
          } else {
            this.translate.get('ERROR.' + res.result?.errorCode).subscribe((res)=> {
              this.toast.present(res, false)
            })
          }
          this.chartReady[podType] = true
 
          res.result.pdas = []
        }
        userInfo = this.localInfo(res);
      }),
      retry(2),
      switchMap(res => res?.result?.pdas), 
      map(res => res?.id), 
      toArray()
     // tap((res)=> console.log(res)
      //)
    )
    .subscribe((res) =>{ 
      if(!ENV.production) console.log(res);
      
      if (res.length == 0) {
        this.api.setToStorage(podType, [] ) //18/04
        this.chartReady[podType] = true
        podType == GS.light?  this.isGas = true : null
      } else {
        
        this.chartMaker(res,  {podType, ...userInfo}, chartDate)
      }
    }, (err) => {
      if (!ENV.production) {
        console.log(err);
      }
      
    })
  }
  /**
   * 
   * @param res array  ID delle bollette 
   * @param info 
   * @param chartDate 
   */
  chartMaker(res, info, chartDate) {
    this.translate.get('MAIN.months').subscribe((res)=> {
      this.months = res
    })


    let {addresses,pods,stats,podType,shortAdd, date, cap, city} = info
    let collection = []
    const aliveStatus = res.map((el, i ) => {return {status: stats[i] ,id: pods[i]} } ).filter((el) => !GS.welcome_board.errorStatus.includes(el.status))

    res.forEach((id , i, arr)=> {
      let req:InvoiceReq = { from: chartDate.from, to: chartDate.to }
      let adr = addresses[i]
      let shadd = shortAdd[i]
      let pd = pods[i]
      let type;
      let status = stats[i]
      let creationDate = date[i]
      let today = (new Date()).toISOString().split('T')[0];
      let userCap = cap[i];
      let userCity = city[i]

      //# 02 2023
      //if (GS.welcome_board.preStasus.includes(status) || GS.welcome_board.closedStatus.includes(status)) this.chartReady[podType] = true;
      if (GS.welcome_board.preStasus.includes(status)) this.chartReady[podType] = true;

      if (status === GS.welcome_board.waitStatus || GS.welcome_board.errorStatus.includes(status) ) {
        const hasSameId = aliveStatus.filter((el) => el.id === pd ).length > 0
        const diffInDays = moment(today).diff(moment(creationDate), 'days');
        
        let progress = diffInDays > 0 && diffInDays < 30 ? 0.3 : diffInDays >= 30 && diffInDays < 60 ? 0.6 : 0.9
        GS.welcome_board.errorStatus.includes(status) ? progress = 1 : (GS.welcome_board.preStasus.includes(status) ? progress = 0.5 : null)
        
        if (GS.welcome_board.errorStatus.includes(status) && hasSameId ) {
          // in caso di fornitura con status di errore e successivamente attivata, nasconde la card 
        } else {
          collection.push({ adr,shadd, pd, id , status, progress, creationDate, userCap, userCity})
          
          this.saveUtencyToStore(podType,collection)

        }

      } 
      if (status === GS.welcome_board.active || GS.welcome_board.closedStatus.includes(status)) {
        if (GS.welcome_board.closedStatus.includes(status)) {
          req.from = creationDate;
        }
        this.getInvoices(req, id).subscribe((detail: InvoiceResp) => {

          podType == GS.light ? type = GS.kw : type = GS.mc

          if (detail.error || detail.result.error) {
            if (res.result?.errorCode == 2130) {
              this.translate.get('ERROR.' + res.result.errorCode).subscribe((res)=> {
                this.toast.presentClose(res, false)
              })
              
            } else if( detail?.error.code == 200 ) {
              this.translate.get('ERROR.odoo').subscribe((res)=> {
                this.toast.presentClose(res, false)
              })
            }else {
              this.translate.get('ERROR.' + detail.error.code).subscribe((res)=> {
                this.toast.present(res, false)
              })

            }
          }

          if (detail.result?.invoices?.length > 0) {
            let fullMonths;
            this.translate.get('MAIN.fullMonths').subscribe((res: [])=> {
              fullMonths = res
            })
            let invoices = detail.result.invoices
            invoices.length > 6?  invoices.length = 6: null

            let eur = invoices.map(({total})=> total+"").reverse()
            let kw = invoices.map(({readings_sum})=> readings_sum+"").reverse()
            let labels = invoices.map(({invoice_reference_month})=> this.months[invoice_reference_month]).reverse()
            let fullLabels = invoices.map(({invoice_reference_month})=> fullMonths[invoice_reference_month]).reverse()
            if (invoices.length < 6) {
              
              this.getMissingLabels(labels, fullLabels, invoices.length,eur ,kw)
            }
            const lastBill = GS.welcome_board.closedStatus.includes(status)? detail.result.invoices[0].invoice_expire_date : '';
            
            collection.push({labels, eur, kw, type, adr, pd,shadd, id , status, fullLabels, creationDate,userCap, userCity, lastBill})

          } else {

            let ko = detail.error?.message ?  detail.error.message : 'N.D.'
            if (GS.welcome_board.closedStatus.includes(status)) {
               this.translate.get('ERROR.no_recent_bills').subscribe((res)=> {
                ko = res
              })
            }
            collection.push({ type, adr, pd,shadd, id , status, ko, creationDate, userCap, userCity})
          }

          this.saveUtencyToStore( podType,collection)
        })
      }
    });
  }

  /**
   * Salva nello storage l'array di bollette per utenza
   * @param podType 
   * @param collection 
   */

  saveUtencyToStore(podType,collection) {
    this.api.getFromStorage(GS[podType]).then((res)=> {
      if (res) {
        
        collection = [...res, ...collection]
        const ids = collection.map(o => o.id)
        collection = collection.filter(({id}, index) => !ids.includes(id, index + 1))
    
      }
      this.chartReady[podType] = true
      podType == GS.gas?  this.gas = collection : this.lux = collection
      this.lux.length == 0 && this.chartReady.light === true && this.gas.length > 0 ? this.isGas = true : this.isGas = false
      this.api.setToStorage(podType,collection ).then((res)=> {
        
      })
    })

    
  }
  /** 
   * Mappa e restituisce le informazioni su abitazione e contratto
   * @param res PdaResponse
   * @returns 
   */
  localInfo(res: PdaResponse) {
    let shortAdd = res.result?.pdas?.
    map(({forniture_address_toponym, forniture_address_street, forniture_address_street2 }) => {
      if (forniture_address_street.length > 10) {
        return forniture_address_toponym[0]+ '. ' + forniture_address_street.substring(0, 10) + '... '+ forniture_address_street2
      } else {
        return forniture_address_toponym[0]+ '. ' + forniture_address_street + ' '+ forniture_address_street2
      }
    })


    let addresses = res.result?.pdas?.
    map(({forniture_address_toponym, forniture_address_street, forniture_address_street2 }) => {
      return forniture_address_toponym+ ' ' + forniture_address_street + ' '+ forniture_address_street2
    })
    let pods = res.result?.pdas?.map(({pod_pdr_code }) => pod_pdr_code)
    let stats = res.result?.pdas?.map(({pda_state }) => pda_state)
    let date = res.result?.pdas?.map(({create_date }) => create_date);
    let cap = res.result?.pdas?.map(({forniture_address_zip }) => forniture_address_zip);
    let city = res.result?.pdas?.map(({forniture_address_city }) => forniture_address_city);
    return {addresses,pods,stats,shortAdd, date, cap, city}
  }

  /**
   * in caso si abbiano meno di 6 bollette per categoria 
   * completa il grafico con valori a 0 e il rispettivo mese
   * andando a ritroso rispetto alla prima bolletta
   * 
   * @param labels array Mesi dell'anno abbreviati
   * @param fullLabels Mesi dell'anno estesi
   * @param arrlength lunghezza dell'array delle bollette gas o elettriche
   * @param eur array degli importi in eur
   * @param un array dei valori in kwh / mcs
   */

  getMissingLabels(labels: string[] , fullLabels: string[], arrlength: number, eur: string[], un: string[] ) {
    let len = 6 - arrlength
    let position = this.months.indexOf(labels[0])
    eur.reverse()
    un.reverse()
    //position == 
    let lebal = labels.reverse()
    let fulllebal = fullLabels.reverse()
    let fullMonths;
    this.translate.get('MAIN.fullMonths').subscribe((res: [])=> {
      fullMonths = res
    })

    for (let index = 1; index <= len; index++) {
      position - index == 0 ? position = 12 + index : null
      lebal.push(this.months[position- index])
      fulllebal.push(fullMonths[position- index])
      eur.push("0")
      un.push("0")
    }
    labels = lebal.reverse()
    fullLabels = fulllebal.reverse()
    eur =  eur.reverse()
    un = un.reverse()
  }




}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.scss'],
})
export class CustomizeComponent implements OnInit {
  podName: string = ''
  myIcon: string = 'a'
  pageReady = false;
  @Input() pd: string;
  constructor(public modalController: ModalController, public api: ApiService) { }

  ngOnInit() {

      this.icons = this.api.icons
      this.api.getFromStorage(this.pd).then((val) => {
        if (val) {
          this.myIcon = val.icon
           val.name.trim() == '' || val.name == this.pd  ? this.podName = '' : this.podName = val.name
        }
        this.pageReady = true
      })
    
  }
  dismiss() {
    const req = {icon: this.myIcon , name: this.podName }
    
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.findIcon()
    this.podName.trim() == '' ?  req.name = this.pd : null
    this.api.personal[this.pd] = req
    this.api.setToStorage(this.pd, req)
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  icons; 
  setPreferences(ev) {
    this.myIcon = ev.detail.value
    const req = {icon: this.myIcon , name: this.podName }
    this.api.personal[this.pd] = req
    return this.api.setToStorage(this.pd, req)
    

  }
  findIcon() {
    //this.icons = this.api.icons
    this.api.getFromStorage(this.pd).then((val)=> {
      
      if (val) {
        for (const key in this.icons) {
          if (Object.prototype.hasOwnProperty.call(this.icons, key)) {
            const element = this.icons[key];
            
            let a = element.filter((el)=> el.val == this.myIcon )
            if (a.length > 0) { 
              a = a.map(({name}) => {
                this.api.personal[this.pd]['icon'] = name
              })
              return 
            }
          }
        } 
      } else {
        this.api.personal[this.pd] = {icon: 'a' , name: this.pd}
        this.api.setToStorage(this.pd, {icon: 'a' , name: this.pd})
      }
    })

  }
  

}

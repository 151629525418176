import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { GlobalSettings as GS } from './../global.settings';
import { ToastService } from './toast.service';
import { AssociateUserReq, CodeRequest, User, UserFireBase, VerifyUser, Customer, userDataChange } from './../models/user.model';
import { HttpClient } from '@angular/common/http';
import { environment as ENV } from './../../environments/environment';
import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { GetOtp, NewUser } from '../models/auth.model';
import firebase from 'firebase/compat/app';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  data;
  boardedSeen;
  header;
  userData
  fireTokenUser;
  isObsolete = false;

  staticpage = ""
  secretCode = ''
  userCode = ''

  public recapUser: Customer
  get uData() {
    return this.userData
  }

  constructor(private http: HttpClient,
    public toast: ToastService,
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public api: ApiService,
    private translate: TranslateService,
    ) { 

      this.fireBaseAuth()
    }

    getAuth( newPassword ) {

      return this.ngFireAuth.authState
    }

    refreshToken() {
      //this.ngFireAuth.setPersistence(this.afStore.firestore.enablePersistence())
      firebase.auth.Auth.Persistence.LOCAL

    }

    fireBaseAuth() {
      
      //console.log('Firebase Constr');
      
      //getIdToken(true)
      this.ngFireAuth.authState.subscribe((user) => {
        if (user) {
          this.fireTokenUser = user;
          this.userData = user;
          localStorage.setItem(GS.store.user, JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem(GS.store.user));
        } else {
          localStorage.setItem(GS.store.user, null);
          
          JSON.parse(localStorage.getItem(GS.store.user));
        }
      });
    }
  
  get isBoarded(): boolean {
    const boarded = JSON.parse(localStorage.getItem(GS.store.boarded));
    return boarded && boarded !== null ||boarded &&  boarded !==  undefined ? true : false;
  }
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem(GS.store.user));
    return user !== null && user.emailVerified !== false ? true : false;
  }
  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem(GS.store.user));
    return user.emailVerified !== false ? true : false;
  }

  signInID(req: NewUser) {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.signIn , req , {headers: this.header} )
  }
  sendOtp(req: GetOtp) {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.sendOtp , req , {headers: this.header} )
  }
  checkOtp(req: GetOtp) {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.checkOtp , req , {headers: this.header} )
  }



  // refreshToken() {
  //   const user = JSON.parse(localStorage.getItem(GS.store.user));
  //   const headerDict = {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //   }
    
  //   const requestOptions = {                                                                                                                                                                                 
  //     headers: new HttpHeaders(headerDict), 
  //   };

  //   return this.http.post('https://securetoken.googleapis.com/v1/token?key=AIzaSyDWRpmmfrDkZuG8gbsXIvVsSV_l_UUvk_Q', 
  //   'grant_type=refresh_token&refresh_token=' + user.stsTokenManager.refreshToken, {})
  // }

  
  SignIn(email, password) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password)
  }
  // Register user with email/password
  RegisterUser(email, password) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }
  // Email verification when new user register
  SendVerificationMail() {
    return this.ngFireAuth.currentUser
      .then((user) => {
        return user.sendEmailVerification();
      })
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }
  // Recover password
  async PasswordRecover(passwordResetEmail) {
    return this.ngFireAuth
      .sendPasswordResetEmail(passwordResetEmail)

  }
  // Sign in with Gmail
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }
  // Auth providers
  AuthLogin(provider) {
    return this.ngFireAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Store user in localStorage
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(
      `users/${user.uid}`
    );
    const userData: UserFireBase = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }
  // Sign-out
  SignOut() {
    return this.ngFireAuth.signOut().then((res) => {
      
      localStorage.removeItem(GS.store.user);
      //this.fireBaseAuth()

      //this.router.navigate(['/']);
    });
  }
 
  verifyUser(req: VerifyUser ) {
    return this.http.post(ENV.api.BASE_URL_IY + ENV.api.endpoint.customer + ENV.api.endpoint.check , req, {headers: this.header})  .pipe(
      switchMap((res) => {
        if (res['result'].errorCode == 2130) {
          this.translate.get('ERROR.' + res['result'].errorCode).subscribe((res)=> {
            this.toast.presentClose(res, false)
          })
        }
        return of(res)
      })
    )
  }
  associateUser(req: AssociateUserReq ) {
    return this.http.post(ENV.api.BASE_URL_IY + ENV.api.endpoint.customer + ENV.api.endpoint.associate , req, {headers: this.header})  .pipe(
      switchMap((res) => {
        if (res['result'].errorCode == 2130) {
          this.translate.get('ERROR.' + res['result'].errorCode).subscribe((res)=> {
            this.toast.presentClose(res, false)
          })
        }
        return of(res)
      })
    )
  }
  sendVerCode(req: CodeRequest ) {
    return this.http.post(ENV.api.BASE_URL_ID +  ENV.api.endpoint.getCode , req, {headers: this.header}).pipe(
      switchMap((res) => {
        // if (res['result'].errorCode == 2130) {
        //   this.translate.get('ERROR.' + res['result'].errorCode).subscribe((res)=> {
        //     this.toast.presentClose(res, false)
        //   })
        // }
        return of(res)
      })
    )
  }
  getUserInfo() {
    return this.http.post(ENV.api.BASE_URL_IY + ENV.api.endpoint.customer , {headers: this.header})
    .pipe(
      switchMap((res) => {
        if (res['result']?.errorCode == 2130) {
          this.translate.get('ERROR.' + res['result'].errorCode).subscribe((res)=> {
            this.toast.presentClose(res, false)
          })
        }
        return of(res)
      })
    )
  }

  getRecapFromServer() {
    this.api.isReady.recap = false

    this.getUserInfo().subscribe((res: Customer | any)=> {
      
      this.api.stored.recap = res.result?.customer || {}

      const user = JSON.parse(localStorage.getItem(GS.store.user));
       this.api.stored.recap.email = user && user !== null && user?.email ? user?.email :  ''

      //res.email = ''
      this.api.stored.recap.lastname == false ?  this.api.stored.recap.lastname = '' : null
      this.api.stored.recap.firstname == false ?  this.api.stored.recap.firstname = '' : null

      this.api.setToStorage(GS.store.recap ,this.api.stored.recap )
      this.api.isReady.recap = true

    })
  }

  changeAddress(req: userDataChange ){
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.changeUserData ,req ,{headers: this.header} )
  }



}

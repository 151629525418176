import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sk-chart',
  templateUrl: './skeleton-chart.component.html',
  styleUrls: ['./skeleton-chart.component.scss'],
})
export class SkeletonChartComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

//import { NativeMarket } from '@capacitor-community/native-market/dist/esm';
// import { Plugins } from '@capacitor/core';


// const { NativeMarket } = Plugins;
// import { Plugins } from '@capacitor/core';
// import { GlobalSettings as GS } from 'src/app/globals.settings';
// const { NativeMarket } = Plugins;
import { GlobalSettings as GS } from './../global.settings';
import { environment as ENV } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, Platform } from '@ionic/angular';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { NativeMarket } from '@capacitor-community/native-market';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor
  
  ( private http: HttpClient,
    private iab: InAppBrowser,
    private plt: Platform,
    ) 
  {}


 

  
  getVersion() {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.version, {})
  }


  async openStore() {
    //console.log('open me');
    if (this.plt.is('android')) {
      //console.log('vai al play store');
      NativeMarket.openStoreListing({
         appId: GS.APPSTORE.android
       })
       
    } else {
      this.iab.create(GS.APPSTORE.ios)
    }
  }


}


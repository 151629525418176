import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { GlobalSettings as GS } from 'src/app/global.settings';
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {

  constructor(public mc: MenuController ,public router: Router) { }
  ngOnInit() {
    this.mc.swipeGesture(false)

  }
  goToLogin() {
    this.router.navigate([GS.pages.login], {replaceUrl: true})
  }

}

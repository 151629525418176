import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-info-self-check',
  templateUrl: './info-self-check.component.html',
  styleUrls: ['./info-self-check.component.scss'],
})
export class InfoSelfCheckComponent implements OnInit {

  constructor(public modalController: ModalController, 
    private api : ApiService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.translate.get('SELF.help').subscribe(res => this.infoData = res)
    ///this.api.getSelfCheck().subscribe(res => this.infoData = res)
  }
  infoData;
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}

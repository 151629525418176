
//import { ENVIRONMENT as DEFAULT_ENVIRONMENT } from './environment';

export const environment = {
  production: true,
  mode: "Production",
  //sandboxIV: true,    //*staging*
  sandboxIV: false,   //**Prod**
  firebaseConfig : {
    apiKey: "AIzaSyDFJmk-YgfOVTLym3W-xQLxhY5U_HNmnA4",
    authDomain: "molten-muse-315009.firebaseapp.com",
    databaseURL: "https://molten-muse-315009-default-rtdb.firebaseio.com",
    projectId: "molten-muse-315009",
    storageBucket: "molten-muse-315009.appspot.com",
    messagingSenderId: "922575508744",
    appId: "1:922575508744:web:6a362396b20efdeff0c8bd",
    measurementId: "G-2ZTHJK89VJ"  
  },
  //stripe: "pk_test_51LEDntLcxVMbw5J4fyvhZirLowAxk7jd5IrqdkoZBUDMUryYYQKfpsRkryNKzrUBAVJNuDwXVOlCm5hIoXnFmuK000ZDb2S2vC", //pubblica test Ajo
  stripe: "pk_live_51LEDntLcxVMbw5J4lvXNflQ9j5gredpNL9SWfe1hnOnvC8TMg8vU3q36yJ7VjbZBKPLLS34FvZsWkw8fVipaMyEI00YLvnnGoP", //pubblica prod Ajo

  //stripe: "pk_test_51H3O23CM49BEcPsvD6X8BY3NpReCGLLQXxmlB542hI9ITZCXZQIGYvt41OblFffw7PEodwUYsVZBUDH4jnHohehl00ScHGoWZD", //pubblica idea docet
  //stripe: "sk_test_51H3O23CM49BEcPsvT0Bl9IJrpr15Mo4qdVyj4RbwgbYE73W89WiB8jYS4KL72YmlsWQyDzhcGZw3LiOOGl3Nk0G50027PqtjQ6", //server idea docet
  pushApi: {
    BASE_URL: 'https://cp.pushwoosh.com/json/1.3/',
    getTags: 'getTags',
    setTags: 'setTags',
    getInbox: "getInboxMessages",
    inboxStatus : "inboxStatus" 
  },


  api: {
    BASE_URL: "/assets/datatest",
    BASE_URL_S: " http://10.20.30.3/ajo/api/",
    //BASE_URL_ID: "https://molten-muse-315009.appspot.com/api/", //** staging */ 
    BASE_URL_ID: "https://backend.ajoenergia.it/api/",  //**prod */
    //BASE_URL_ID: "https://molten-muse-315009.oa.r.appspot.com/api/",  // test scalapay
   // BASE_URL_IY: "https://ajoenergia.innovyou.co/crm/api",//**staging*
    BASE_URL_IY: "https://agenzie.ajoenergia.it/crm/api", // **Prod**
    //staging
    endpoint: {
      customer: "/customer",
      check: "/check",
      invoice: "/invoice",
      
      pdas: "/pda",
      invoices: "/invoices",
      download: "/download",
      sendCode: "/sendcode",
      associate: "/associate",
      readings: "/readings",
      //Idea Docet ep.
      sendOtp: "auth/send-otp-code",
      checkOtp: "auth/check-otp-code",
      getCode: "auth/get-activation-code",
      sendSelf: "misc/send-autolettura",
      ticket: "misc/send-ticket",
      offers: "static/get-offers",
      manuals: "static/get-manuals",
      contacts: "static/get-data-support",
      signIn: "auth/register-user",
      faq: "static/get-faq",
      homeslider: "static/get-home-slider",
      news: "static/get-news",
      welcome: "static/get-welcome",
      version: "static/data-initialize",
      stripeIntent: "payment/stripe-request-intent",
      stripeConfirm: "payment/stripe-confirm-intent",
      scalaPayLink: "payment/request-scalapay",
      scalaPayCreate: "payment/createorder-scalapay",
      scalaPayCapture: "payment/captureorder-scalapay",
      scalaPayStatus: "payment/statusorder-scalapay",
      changeUserData: "misc/change-user-data" //da confermare
    },
    //test locale
    icons: {
      path : "/assets",
      icons: "/icons.JSON"
    },
    selfcheck : {
      path : "/selfcheck",
      info: "/info.JSON"
    },
    customerInfo: "/customer_info.JSON",
    version: "/version.JSON"
  },
};

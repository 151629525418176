import { Injectable } from '@angular/core';
import { Directory } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Filesystem } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private opener : FileOpener,
    
  ) { }

  saveAndOpenPdf(pdf: string, filename: string) {
    const currentDate = new Date().toLocaleString().replace(/[,:\s\/]/g, '-');
    const fileName = `${currentDate}${filename}`;

    Filesystem.writeFile({ 
      path:fileName, 
      data:pdf,
      directory: Directory.Documents
    })
    .then((x) => {
      this.opener.open( x.uri, 'application/pdf')
        .catch(() => {
            //console.log('Error opening pdf file 1');
        });
    })
    .catch(() => {
        //console.error('Error writing pdf file 0');
    });
  }




}

import { Router } from '@angular/router';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { ContactusComponent } from '../contactus/contactus.component';
import { GlobalSettings as GS } from 'src/app/global.settings';
@Component({
  selector: 'app-info-boarding',
  templateUrl: './info-boarding.component.html',
  styleUrls: ['./info-boarding.component.scss'],
})
export class InfoBoardingComponent implements OnInit {

  constructor(public modalController: ModalController,
    public router: Router) { }


  ngOnInit() {
  }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  getActivationCode() {
    this.dismiss();
    this.router.navigate([GS.pages.get_code])
  }
  async missingCode() {
    this.dismiss();
    
    const modal = await this.modalController.create({
      component: ContactusComponent,
      presentingElement: await this.modalController.getTop(),
      componentProps: {},
      mode: 'ios'
    });
    return await modal.present();
  }


}

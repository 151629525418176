import { ContactusComponent } from './../../modals/contactus/contactus.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { NewUser } from 'src/app/models/auth.model';
import { CodeRequest } from 'src/app/models/user.model';
import { InfoBoardingComponent } from '../../modals/info-boarding/info-boarding.component';

@Component({
  selector: 'app-reqform',
  templateUrl: './reqform.component.html',
  styleUrls: ['./reqform.component.scss'],
})
export class ReqformComponent implements OnInit {


  ngOnInit() {}

  constructor(public modalController: ModalController,
    private routerOutlet: IonRouterOutlet, 
    public router: Router
    ) {
    this.inputControl() 
  }
  afSubmit = false;
  steps = 0
  modal = {}
  codeForm: FormGroup;

  @Output() isSubmit = new EventEmitter();
  @Input() modalData;

  inputControl() {

    this.codeForm = new FormGroup({
      userCode: new FormControl('', Validators.required),

    });

  }
  codeSubmit() {
    this.afSubmit = true;
    
    if (this.codeForm.valid ) {
      let req: NewUser = {
        Name: "",
        Surname: "",
        Email: "",
        Password: "",
        Activationcode: "",
        Phonenumber: "",
        Customerid: this.codeForm.value.userCode//{
        //enerp_customer_code: ,
        //sandbox: true
      }
      this.isSubmit.emit(req)
    }
  }

  checkValidate(form, submit , ctr) {
    if (submit) {
      return form.hasError('required' , ctr) 
    }
  }
  async presentModal() {
    const modal = await this.modalController.create({
      component: InfoBoardingComponent,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: this.modalData,
      mode: 'ios'
    });
    return await modal.present();
  }
  async missingCode() {
    const modal = await this.modalController.create({
      component: ContactusComponent,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: this.modalData,
      mode: 'ios'
    });
    return await modal.present();
  }


}


import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sup-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class SupAccordionComponent implements OnInit {

  constructor(
  public iab: InAppBrowser,
) { }

  ngOnInit() {}
  @Input() doc = {
    icon: '',
    name: '',
    manuals: ''

  }

  async openDoc(path) {
    this.iab.create(path, '_system')
  }

  

}

import { LoggedGuard } from './guards/logged.guard';
import { AuthGuard } from './guards/auth.guard';
import { BoardingGuard } from './guards/boarding.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'boarding',
    pathMatch: 'full'
  },
  {
    path: 'offline',
    loadChildren: () => import('./pages/offline/offline.module').then( m => m.OfflinePageModule)
  },

  {
    path: 'upgrade',
    loadChildren: () => import('./pages/upgrade/upgrade.module').then( m => m.UpgradePageModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canLoad: [LoggedGuard]
  },
  {
    path: 'code',
    loadChildren: () => import('./pages/getcode/getcode.module').then( m => m.GetcodePageModule)
  },
  {
    path: 'boarding',
    loadChildren: () => import('./pages/boarding/boarding.module').then( m => m.BoardingPageModule),
    canLoad: [BoardingGuard]
  },
  {
    path: 'bill/:id',
    loadChildren: () => import('./pages/bill/bill.module').then( m => m.BillPageModule),
    canLoad: [AuthGuard]

  },

  {
    path: 'activation',
    loadChildren: () => import('./pages/activation/activation.module').then( m => m.ActivationPageModule),
    canLoad: [LoggedGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/tabnav/tabnav.module').then( m => m.TabnavPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'scalapaysuccess',
    loadChildren: () => import('./pages/scalapaysuccess/scalapaysuccess.module').then( m => m.ScalapaysuccessPageModule),
  },
  {
    path: 'scalapayfail',
    loadChildren: () => import('./pages/scalapayfail/scalapayfail.module').then( m => m.ScalapayfailPageModule),
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

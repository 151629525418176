import { ApiService } from 'src/app/services/api.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {

  constructor(private iab: InAppBrowser,
    public api: ApiService) { }

  ngOnInit() {}
  openUrl() {
    this.iab.create(this.api.stored.calendar, '_self')
  }

}

import { UpdateService } from './update.service';
import { environment as ENV } from './../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { PhotoService } from './photo.service';
import { ApiService } from './api.service';
import { PdaService } from './pda.service';
import { Injectable } from '@angular/core';
import { GlobalSettings as GS } from '../global.settings';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { PushService } from './push.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Router } from '@angular/router';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    public auth: AuthService,
    public pda: PdaService,
    public api: ApiService,
    public photoSvc: PhotoService,
    public translate: TranslateService,
    private update: UpdateService,
    private plt: Platform,
    private push: PushService,
    private appVersion: AppVersion,
    public router: Router,


  ) { }
  async setStorage() {
    
    let toSync = false;

    this.api.findRangeDate()
    let [
      links, photoList, slides, homeNews, faq,
      news, offers, manuals, recUser, light, gas] = 
    await  Promise.all([
      JSON.parse(localStorage.getItem(GS.store.links)),
      JSON.parse(localStorage.getItem(GS.store.photos)),
      JSON.parse(localStorage.getItem(GS.store.slides)),
      JSON.parse(localStorage.getItem(GS.store.homeNews)),
      JSON.parse(localStorage.getItem(GS.store.faq)),
      JSON.parse(localStorage.getItem(GS.store.news)),
      JSON.parse(localStorage.getItem(GS.store.offers)),
      JSON.parse(localStorage.getItem(GS.store.manuals)),
      JSON.parse(localStorage.getItem(GS.store.recap)),
      JSON.parse(localStorage.getItem(GS.store.light)),
      JSON.parse(localStorage.getItem(GS.store.gas))
    ])
    const res = await this.api.getFromStorage(GS.store.lastSync)//.then((res)=> {
      const now = new Date().getTime()
      if (res + GS.RefreshTime < now ) {
        toSync = true
        await this.api.setToStorage(GS.store.lastSync, now)
        links = slides = homeNews = faq =
        news = offers = manuals = light = gas = null
      }
    
    links  ? this.api.stored.links = links : this.api.getLinksFromServer()
    photoList ? this.photoSvc.photos = photoList : this.photoSvc.photos = {webviewPath : GS.defaultImg, filepath : '' };
    slides ? this.api.stored.slides = slides :  this.api.getSlideFromServer()
    homeNews ? this.api.stored.homeNews = homeNews : this.api.getHomeNewsFromServer()
    faq ? this.api.stored.faq = this.api.stored.filteredfaq = faq :  this.api.getFaqFromServer();
    news ? this.api.stored.news = news : this.api.getNewsFromServer()
    offers ? this.api.stored.offers = offers : this.api.getOffersFromServer()
    manuals ?  this.api.stored.manuals = manuals : this.api.getManualFromServer()
    recUser ?  this.api.stored.recap = recUser : this.auth.getRecapFromServer()
    light ? this.pda.lux = light : this.pda.findPdas(GS.store.light,this.api.chartDate)
    gas ? this.pda.gas = gas :  this.pda.findPdas(GS.store.gas, this.api.chartDate)
    if (this.pda.gas.length > 0 && this.pda.lux.length == 0) this.pda.isGas = true
 
  }

  async refreshHomePage(ev) {
    this.api.isReady.slides = false
    this.api.isReady.homeNews = false
    this.pda.chartReady.gas = false
    this.pda.chartReady.light = false
    localStorage.removeItem(GS.store.slides)    
    localStorage.removeItem(GS.store.homeNews) 
    localStorage.removeItem(GS.store.light)
    localStorage.removeItem(GS.store.gas)
    this.pda.gas = [];
    this.pda.isGas = false;
    this.pda.lux = [];   
    this.api.stored.slides = []
    this.api.stored.homeNews = []
    
    const user = await JSON.parse(localStorage.getItem(GS.store.user));
    const expireTime = user? user.stsTokenManager.expirationTime : null
    const now = new Date().getTime()
    if (now + 200000 > expireTime  ) {
      this.auth.ngFireAuth.authState.subscribe((user) => {
        if (user) {
          user.getIdToken(true).then((res)=> {
            this.api.getFromStorage(GS.store.user).then((user)=> {
              user.stsTokenManager.accessToken = res;
                this.api.setToStorage(GS.store.user, user ).then(()=> {
                this.api.findRangeDate()
                this.api.getHomeNewsFromServer();
                this.api.getSlideFromServer();
                this.pda.findPdas(GS.light,this.api.chartDate)
                this.pda.findPdas(GS.gas,this.api.chartDate) 
                setTimeout(() => {
                  ev.target.complete();
                }, 2000)
              })
            })
          })
        } else {
          setTimeout(() => {
            ev.target.complete();
          }, 2000)
        }
      })

    } else {
      this.api.findRangeDate()
      this.api.getHomeNewsFromServer();
      this.api.getSlideFromServer();
      this.pda.findPdas(GS.light,this.api.chartDate)
      this.pda.findPdas(GS.gas,this.api.chartDate) 
      setTimeout(() => {
        ev.target.complete();
      }, 2000);
    }
    
        
  }

  async refreshConsumerPage(ev) {
    this.pda.chartReady.gas = false
    this.pda.chartReady.light = false
    localStorage.removeItem(GS.store.light)
    localStorage.removeItem(GS.store.gas)
    this.pda.gas = [];
    this.pda.isGas = false;
    this.pda.lux = [];

    const user = await JSON.parse(localStorage.getItem(GS.store.user));
    const expireTime = user? user.stsTokenManager.expirationTime : null
    const now = new Date().getTime()

    if (now + 200000  > expireTime ) {
      this.auth.ngFireAuth.authState.subscribe((user) => {
        if (user) {
          user.getIdToken(true).then((res)=> {
           // console.log(res);
            this.api.getFromStorage(GS.store.user).then((user)=> {
              user.stsTokenManager.accessToken = res;
              this.api.setToStorage(GS.store.user, user ).then(()=> {
                this.api.findRangeDate()
                this.pda.findPdas(GS.light,this.api.chartDate)
                this.pda.findPdas(GS.gas,this.api.chartDate)
                setTimeout(() => {
                  ev.target.complete();
                }, 2000)
  
              })
            })
        
          })
        } else {
          setTimeout(() => {
            ev.target.complete();
          }, 2000);      }
      })

    } else {
      this.api.findRangeDate()
      this.pda.findPdas(GS.light,this.api.chartDate)
      this.pda.findPdas(GS.gas,this.api.chartDate)
      setTimeout(() => {
        ev.target.complete();
      }, 2000);
}

  }


  // #2023 

  async initializeApp() {
   
    
    await this.api.getFromStorage(GS.store.lang).then(res => {
      
      if(!!res) {
        this.translate.setDefaultLang(res);
        GS.DEFAULT_LANG = res.toUpperCase();
 
      } else {
        const browserLang = this.translate.getBrowserLang();
        this.translate.setDefaultLang(browserLang); 
        this.api.setToStorage(GS.store.lang , browserLang)
        GS.DEFAULT_LANG = browserLang.toUpperCase();
      }
      
    })
    this.api.getIcons().subscribe(res => this.api.icons = res)
    const status = await Network.getStatus();
    this.api.getFromStorage(GS.store.darkMode).then(res => {
      if(res) {
        if (!document.body.classList.contains('dark')) {
          document.body.classList.add('dark')
          if (this.plt.is('capacitor')) {
            StatusBar.setBackgroundColor({color: '#171717'});
            StatusBar.setStyle( {style: Style.Dark })
            StatusBar.setOverlaysWebView({overlay: false})
          }
        }
      } else {
        if (this.plt.is('capacitor')) {
          StatusBar.setBackgroundColor({color: '#FFFFFF'})
          StatusBar.setStyle( {style: Style.Light })
          StatusBar.setOverlaysWebView({overlay: false})
        }
        //this.statusBar.backgroundColorByHexString('#FFF');
      }
      this.api.stored.isDark = res
    })
    if (!status.connected) {
      this.router.navigate([GS.pages.offline], { replaceUrl: true }); 
      return
    }

    this.update.getVersion().subscribe(async (info: any)=> {
      this.api.stored.privacyUrl = info['other'].privacyurl;
      this.api.stored.calendar = info['other'].calendar;

      if (!ENV.production) console.log('%c check version', 'color:blue', info);

      if (this.plt.is('capacitor')) {
          
          const and = this.plt.is('android')
          const ios = this.plt.is('ios')
          if (and || ios) {

            let plat = and? 'android' : 'ios' 
            const version = await this.appVersion.getVersionNumber()
            const splittedvs = version?.split('.').join('')
            const servervsn =  info['version'][plat].split('.').join('')
            
            if (!ENV.production)console.log( 'split + server ', splittedvs ,servervsn);
            if (+servervsn > +splittedvs) {
              SplashScreen.hide()
              this.router.navigate([GS.pages.upgrade], { replaceUrl: true });          
            } else {
              this.standardBoot().then(()=> {                
                this.push.initOnDeviceReady(false)
              })
            }
          }
      } else {
          await this.standardBoot()
          // .then(()=> {
          //   this.push.initOnDeviceReady(false)
          // })

      //   this.initializeApp()
      }
    })
    
    


 
  }
  async standardBoot() {
    if (this.auth.isLoggedIn) {
      const user = await JSON.parse(localStorage.getItem(GS.store.user));
      const expireTime = user? user.stsTokenManager.expirationTime : null
      const now = new Date().getTime()
      
      if (now + 200000  > expireTime ) {
        const oldToken = this.api.logToken(user.stsTokenManager.accessToken)

        this.auth.ngFireAuth.authState.subscribe((user) => {
          if (user) {
            user.getIdToken(true).then((res)=> {
              if (!ENV.production) {
                console.log('%c Old Token ', 'color: purple; border: 1px solid purple;border-radius:5px ; padding:2px;margin: 10px 0;', oldToken);
                console.log('%c New Token ', 'color: purple; border: 1px solid purple;border-radius:5px ; padding:2px;margin: 10px 0;',this.api.logToken(res));
              }
              this.api.getFromStorage(GS.store.user).then((user)=> {
                user.stsTokenManager.accessToken = res;
                this.api.setToStorage(GS.store.user, user ).then(()=>{
                  this.setStorage().then(()=> {
                    if (this.auth.staticpage) {
                      //this.router.navigate(['main/home'])
                      this.router.navigate([this.auth.staticpage],{replaceUrl: true})

                    } else {

                      this.router.navigate([GS.pages.home], { replaceUrl: true });
                      !ENV.production && console.log('%c [loading completed] %c - User is logged -', 'border-radius: 4px 0 0 4px;padding: 4px;background-color: #3598ba; color:white', 'border-radius: 0 4px 4px 0;padding: 4px;background-color: #45b486; color:white' )
                    }
                    SplashScreen.hide()
                  })
                })
              })
            })
          }
        })
      } else {
        this.setStorage().then(()=> {
          SplashScreen.hide()
          if (this.auth.staticpage) {
            this.router.navigate([this.auth.staticpage],{replaceUrl: true})
          } else {
            this.router.navigate([GS.pages.home], { replaceUrl: true });
            !ENV.production && console.log('%c [loading completed] %c - User is logged -', 'border-radius: 4px 0 0 4px;padding: 4px;background-color: #3598ba; color:white', 'border-radius: 0 4px 4px 0;padding: 4px;background-color: #45b486; color:white' )
          }
        })
      }
      //this.auth.isObsolete && this.router.navigate([GS.pages.upgrade], { replaceUrl: true});
    } else {
      if (this.auth.staticpage) {
        this.router.navigate([this.auth.staticpage],{replaceUrl: true})
      } else {
        this.router.navigate([GS.pages.boarding], { replaceUrl: true });
        //this.auth.isObsolete && this.router.navigate([GS.pages.upgrade], { replaceUrl: true});
        !ENV.production && console.log('%c [loading completed] %c - User not logged -', 'border-radius: 4px 0 0 4px;padding: 4px;background-color: #3598ba; color:white', 'border-radius: 0 4px 4px 0;padding: 4px;background-color: orange; color:#FFF' )
      }
      SplashScreen.hide()
    }
  }

}

import { TranslateService } from '@ngx-translate/core';
import { GlobalSettings as GS} from './../global.settings';
import { ToastController } from '@ionic/angular';
import { environment as ENV  } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
  } from '@angular/common/http';
  import { Observable, throwError } from 'rxjs';
  import { map, catchError } from 'rxjs/operators';
  import { Injectable } from '@angular/core';


  @Injectable()
  export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private api : ApiService,
        public toast: ToastController,
        public translate: TranslateService
       ) { }

    async presentToast(msg , cssclass = 'ajo-toast') {
      const toast = await this.toast.create({
        message: msg,
        duration: 2000,
        cssClass: cssclass
      });
      toast.present();
    }

    getUrl(evnt:HttpResponse<any> | HttpRequest<any>) {
      let x

      if(evnt.url.includes(ENV.api.BASE_URL_IY)) {
       x = evnt.url.toString().replace(ENV.api.BASE_URL_IY, '')
      }
      if(evnt.url.includes(ENV.api.BASE_URL_ID)) {
       x = evnt.url.toString().replace(ENV.api.BASE_URL_ID, '')
      }
      if(evnt.url.includes(ENV.api.BASE_URL)) {
       x = evnt.url.toString().replace(ENV.api.BASE_URL+"/", '')
      }
      if(evnt.url.includes('/assets/i18n')) {
       x = evnt.url.toString().replace('/assets/i18n', 'i18n')
      }
      if(evnt.url.includes('/assets/icons')) {
       x = evnt.url.toString().replace('/assets/icons', 'icons')
      }
      return x

    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let source = request.url.includes('/assets/')? "[Assets]": " [HTTP] "
      let color = source.includes('Assets')? '#b3ae4f': '#2abcd9'
      const token = this.api.token

      if (!ENV.production) {
      console.log("%c"+source+" Req: %c" + this.getUrl(request) +" %o",
      'background:#FFF; border: 1px solid #3598ba; border-radius:5px 0 0 5px ; padding:2px;color: '+color+';margin: 10px 0;',
      'background:#3598ba; border: 1px solid #3598ba; border-radius:0 5px 5px 0; padding:2px;color: #FFF;margin: 10px 0;',
        request);
      }


      //Authentication by setting header with token value
      if (token) {
        //console.log(token);

        request = request.clone({
          setHeaders: {
            'Authorization': 'Bearer ' + token
          }
        });
      }
      // if (request.url.includes("scalapay")) {
      //   //console.log(token);

      //   request = request.clone({
      //     setHeaders: {
      //       'Authorization': 'Bearer ' + 'qhtfs87hjnc12kkos'
      //     }
      //   });
      //   request = request.clone({
      //     setHeaders: {
      //       'Access-Control-Allow-Origin' : '*'
      //     }
      //   });
      //   request = request.clone({
      //     setHeaders: {
      //       "accept" : 'application/json'
      //     }
      //   });
      //   request = request.clone({
      //     setHeaders: {
      //       'content-type': 'application/json'
      //     }
      //   });
      // }


    //   if (!request.headers.has('Content-Type')) {
    //     request = request.clone({
    //       setHeaders: {
    //         'content-type': 'application/json'
    //       }
    //     });
    //   }

    //   request = request.clone({
    //     headers: request.headers.set('Accept', 'application/json')
    //   });


      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {

          if (event instanceof HttpResponse) {
            if (!ENV.production) {

            console.log("%c"+source+" Res: %c" + this.getUrl(request) +"%o",
            'background:#FFF; border: 1px solid #45b486; border-radius:5px 0 0 5px ; padding:2px;color: '+color+';margin: 10px 0;',
            'background:#45b486; border: 1px solid #45b486; border-radius:0 5px 5px 0; padding:2px;color: #FFF;margin: 10px 0;',
             event);


            }
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
            if (!ENV.production) {

          console.log("%c"+source+" Err: %c" + this.getUrl(request) +"%o",
           'background:#FFF; border: 1px solid #E18D8A; border-radius:5px 0 0 5px ; padding:2px;color: #E18D8A;margin: 10px 0;',
          'background:#E18D8A; border: 1px solid #E18D8A; border-radius:0 5px 5px 0; padding:2px;color: #FFF;margin: 10px 0;'
          , error);
          if (error.error.detail == "Opsss...qualcosa è andato storto. Errore: The user with the provided email already exists (EMAIL_EXISTS).") {
            this.translate.get('ERROR.pb_mail').subscribe((res)=> {
              this.presentToast(res, 'ajo-toast-error')
            })
          } else {
            this.presentToast(error.error.detail || error.error.message || error.message , 'ajo-toast-error')
          }
          //if(request.url.includes(ENV.api.BASE_URL_IY) ||request.url.includes(ENV.api.BASE_URL_ID) ) {
          //} else {
          //  this.presentToast(error.message || error.error.detail || error.error.message , 'ajo-toast-error')

         // }
            }
          return throwError(error);
        }));
    }
    
  
  
  }
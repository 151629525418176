import { GlobalSettings as GS } from './../../global.settings';
import { TicketComponent } from './../modals/ticket/ticket.component';
import { Component, OnInit, Input } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-welcomebox',
  templateUrl: './welcomebox.component.html',
  styleUrls: ['./welcomebox.component.scss'],
})
export class WelcomeboxComponent implements OnInit {

  constructor(public modalController: ModalController,
    private routerOutlet: IonRouterOutlet, ) { }

  ngOnInit() {}
  @Input() progress: string = '0';
  @Input() podNumber : string = ''
  @Input() status: string = ''


  preStasus = GS.welcome_board.preStasus
  waitStatus = GS.welcome_board.waitStatus
  errorStatus = GS.welcome_board.errorStatus
  closedStatus = GS.welcome_board.closedStatus

  async missingCode() {
    const modal = await this.modalController.create({
      component: TicketComponent,
      presentingElement: this.routerOutlet.nativeEl,
      //componentProps: this.modalData,
      mode: 'ios'
    });
    return await modal.present();
  }

}


export enum EnergyType {
    gas = "gas",
    light = "ee"
}


export interface InvoiceReq {
    from: string //yyyy-mm-dd
    to: string//yyyy-mm-dd
}
export interface InvoiceResp {
    jsonrpc: string,
    id: string,
    result?: InvoiceResult,
    error?: InvoiceError
 }
 export interface InvoiceResult {
    success: boolean,
    invoices?: Bill[],
    error?: string,
    errorCode?: number,
    responseStatus?:string
 }
 export interface InvoiceDownload {
    jsonrpc: string,
    id: string,
    result?: InvoiceResDownload,
    error?: InvoiceError
 }
 export interface InvoiceResDownload {
    invoice?: InvoiceB64[]
    success: true
    error?: string
    errorCode?: number,
 }
 export interface InvoiceB64 {
    document_type: string
    file_content: string
    file_name: string
    id: number
 }

  
export interface InvoiceError {
    code: number,
    message: string,
    data: InvoiceErrorData
}

export interface InvoiceErrorData {
    name: string,
    debug: string,
    message: string,
    arguments: string[]
    exception_type: string
}
 
export enum DocsType {
    postal = 'BP',
    simple =  'S',
    detail = 'D'
}
export interface PdaRequest {
    pda_type? : EnergyType
}

export interface PdaResponse {
    jsonrpc: 2.0,
    id: null,
    result: PdaResults,
    error? : any
 }
export interface PdaResults {
    success: boolean,
    pdas?: PdaDetails[],
    error?: string,
    errorCode?: number,
    responseStatus? : string
 }

 export interface PdaDetails {
    id: string,
    create_date: string, 
    pda_state: string,
    pda_type: EnergyType, 
    pod_pdr_code: string
    forniture_address_toponym: string
    forniture_address_street: string
    forniture_address_street2: string,  
    forniture_address_zip: string,  
    forniture_address_city:string
    forniture_residence: boolean,  
    product_id: ProductId
 }

 export interface ProductId {
    id: number, 
    name: string
 }
 export interface PdaDetailsReq {
    from: string,
    to: string,
    category? :PdaCategory, //< [optional] (set) Valori Tab 3
    reading_type? :PdaReadingType, //< [optional] (set) Valori Tab 4
 }
 export enum PdaCategory {
     power = "PO",
     kr = "KRH",
     kw = "KWH",
     mc = "M3",
 }
 export enum PdaReadingType {
     real = "LE",
     periodic = "P",
 }

 export interface PdaReadingResp {
    jsonrpc: string,
    id: string,
    result: {
        error?: string,
        errorCode?: number,
        responseStatus? : string
        success: boolean,
        pdas?: PdaEnergyResp[] |  PdaGasResp[]
    }
 }
 export interface PdaEnergyResp {
    id: number
    pod: string
    time_slot_id: number
    category: PdaCategory
    reading_date: string
    reading_type: PdaReadingType
    reading_value: number
    reading_type_description: string
    doc_type_description: string
}
 export interface PdaGasResp {
    id: number,
    pdr: string,
    category: PdaCategory
    reading_date: string
    reading_type: PdaReadingType
    reading_value: string,
    reading_type_description: string,
    remi: string,
    doc_type: string,
    doc_type_description: string        
}

export interface Bill {
    pda_type: EnergyType,
    document_number: string
    cost_summary_ids: BillDetails[]
    readings_sum: number,  //kw  o mc
    total: number,  // totale
    invoice_id: {
        id: number
    },
    crai_total: number,
    id: number,
    invoice_reference_month: number, //mese
    is_paid: boolean, //
    invoice_reference_year: number //anno
    taxable: number // Imponibile
    crai_parity_total: number // canone Rai
    invoice_release_date: string
    invoice_expire_date: string
    snaps?: any
    snap?: any
    pod?: any
    scalapay_request?: boolean
} 

export interface BillDetails {
    cost_description: string
    id: number
    not_taxable: number
    taxable: number
    total: number
    vat: number
}

import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './../../../services/toast.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from './../../../services/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController  } from '@ionic/angular';
import { TicketResponse } from '../contactus/contactus.component';
import { GlobalSettings as GS } from 'src/app/global.settings';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit {
  isSubmit: boolean = false
  pods = []
  attachmentFile;
  pdfName: any;

  constructor(public modalController: ModalController,
    private translate: TranslateService,
    public toast: ToastService,
    public api: ApiService,
    public auth: AuthService) { 
    this.inputControl()
  }
  ticketForm: FormGroup
  @Input() email;
  ngOnInit() {
    this.getPod()
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  ticketSubmit() {
    this.isSubmit = true
    if (this.ticketForm.valid ) {
      let req = {
        Name: this.api.stored.recap.firstname + ' ' + this.api.stored.recap.lastname,
        ContractId: this.ticketForm.value.pod,
        Message: this.ticketForm.value.text,
        PhoneNumber: this.ticketForm.value.phone,
        Email: this.api.stored.recap.email,
        CustomerId: this.api.stored.recap.enerp_customer_code+"",
        ContactType : this.ticketForm.value.option,
        Modulo: this.attachmentFile

      }
      this.toast.loader();
      this.api.sendTicket(req).subscribe((res: TicketResponse) => {
        this.toast.dismissLoading()
        if (res.success){ 
          this.translate.get('SUCCESS.ticket').subscribe((res)=> {
            this.toast.present(res)
            this.dismiss()
          })
        } else {
          this.translate.get('ERROR.1000').subscribe((res)=> {
            this.toast.present(res, false)
            this.dismiss()
          })
        }
      }, err => {
        this.toast.dismissLoading();
      })
    }
    
  }
  inputControl() {
    this.ticketForm = new FormGroup({
      text: new FormControl('', Validators.required),
      pod: new FormControl('', Validators.required),
      option: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
    })
  }

  getPod() {
    const light = JSON.parse(localStorage.getItem(GS.store.light));
     let lux = light.length > 0  ? light.map(({pd})=> pd) :  []

    const gas = JSON.parse(localStorage.getItem(GS.store.gas));
    let gs = gas.length > 0  ? gas.map(({pd})=> pd) :  []
    this.pods = gs.concat(lux)
  }
  checkValidate(ctr) {
    if (this.isSubmit) {
      return this.ticketForm.hasError('required' , ctr)
    }
  }
  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }

  onSelectFile(event) {
  
    if (event.target.files && event.target.files[0]) {
      let newInstance = this.getFileReader();
      newInstance.readAsDataURL(event.target.files[0]);
      newInstance.onload = (imgsrc) => {
        let url = (imgsrc.target as FileReader).result;
        this.attachmentFile = url;
      }
      let fileList: FileList = event.target.files;  
      let file: any = fileList[0];
      this.pdfName = file.name;
      ;
    }
  }


  
  

}

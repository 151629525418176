import { ForgotEmailComponent } from './../components/modals/forgot-email/forgot-email.component';
import { ChartxlComponent } from './../components/chartxl/chartxl.component';
import { SkeletonChartComponent } from './../components/skeleton/skeleton-chart/skeleton-chart.component';
import { HeadbackComponent } from './../components/headers/headback/headback.component';
import { BillDetailComponent } from 'src/app/components/bills/bill-detail/bill-detail.component';
import { InfoSelfCheckComponent } from './../components/modals/info-self-check/info-self-check.component';
import { FooterboxComponent } from './../components/app/footerbox/footerbox.component';
import { SupAccordionComponent } from './../components/support/accordion/accordion.component';
import { ForgotpswComponent } from './../components/modals/forgotpsw/forgotpsw.component';
import { ContactusComponent } from './../components/modals/contactus/contactus.component';
import { NewsMainComponent } from './../components/news/news/news.component';
import { ReqSuccessComponent } from './../components/getcode/reqsuccess/success.component';
import { WelcomeboxComponent } from './../components/welcomebox/welcomebox.component';
import { InfoBoardingComponent } from './../components/modals/info-boarding/info-boarding.component';
import { ReqformComponent } from './../components/getcode/reqform/reqform.component';
import { AccordionComponent } from './../components/faq/accordion/accordion.component';
import { BoarderComponent } from './../components/boarding/boarder/boarder.component';
import { SuccessComponent } from './../components/activation/success/success.component';
import { FormComponent } from './../components/activation/form/form.component';
import { BannerComponent } from './../components/support/banner/banner.component';
import { DocsComponent } from './../components/support/docs/docs.component';
import { LinksComponent } from './../components/support/links/links.component';
import { NewsComponent } from './../components/home/news/news.component';
import { HomesliderComponent } from './../components/home/homeslider/homeslider.component';
import { TicketComponent } from './../components/modals/ticket/ticket.component';
import { HeaderFullComponent } from './../components/headers/header-full/header-full.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { File } from '@awesome-cordova-plugins/file/ngx';
// import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
//import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';

import { CommonModule } from "@angular/common";
import { IonicModule,  } from '@ionic/angular';
import { ChartComponent } from '../components/chart/chart.component';
import {  HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { PaymentsComponent } from '../components/modals/payments/payments.component';
import { Scroll } from '../directive/scroll.module';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/i18n/" , ".json"); //"/assets/i18n/"
}

@NgModule({
  declarations: [
    ForgotEmailComponent,
    HeadbackComponent,
    SkeletonChartComponent,
    InfoSelfCheckComponent,
    ContactusComponent,
    WelcomeboxComponent,
    FooterboxComponent,
    NewsMainComponent,
    ReqSuccessComponent,
    HeaderFullComponent,
    ChartComponent,
    TicketComponent,
    HomesliderComponent,
    NewsComponent,
    LinksComponent,
    DocsComponent,
    BannerComponent,
    FormComponent,
    SuccessComponent,
    BoarderComponent,
    AccordionComponent,
    ReqformComponent,
    ChartxlComponent,
    InfoBoardingComponent,
    ForgotpswComponent,
    SupAccordionComponent,
    BillDetailComponent,
    PaymentsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    CommonModule,
    Scroll,

    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),

  ],
  exports: [
    Scroll,
    InfoSelfCheckComponent,
    ContactusComponent,
    ForgotpswComponent,
    ReactiveFormsModule,
    FormsModule,
    ReqSuccessComponent,
    WelcomeboxComponent,
    HeaderFullComponent,
    ChartComponent,
    TranslateModule,
    TicketComponent,
    HomesliderComponent,
    NewsComponent,
    LinksComponent,
    ChartxlComponent,
    DocsComponent,
    BannerComponent,
    FormComponent,
    SuccessComponent,
    BoarderComponent,
    AccordionComponent,
    ReqformComponent,
    InfoBoardingComponent,
    NewsMainComponent,
    SupAccordionComponent,
    FooterboxComponent,
    BillDetailComponent,
    SkeletonChartComponent,
    PaymentsComponent,
    ForgotEmailComponent,

    HeadbackComponent
 

  ],
   providers: [ FileOpener ]
})
export class SharedModule {}

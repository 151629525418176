import { ApiService } from 'src/app/services/api.service';
import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { GlobalSettings as GS } from '../global.settings';
// import { Plugins } from '@capacitor/core';
// const { Modals } = Plugins;
@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  
  public photos = {webviewPath : GS.defaultImg, filepath : '' }
  private PHOTO_STORAGE: string = 'photos';
  base64: string | Blob = GS.defaultImg
  myNewPhoto;
  isReady = false
  constructor(private platform: Platform,
    public api: ApiService
    ) {
      const photoList = JSON.parse(localStorage.getItem(GS.store.photos));
      photoList ? this.photos = photoList : this.photos = {webviewPath : GS.defaultImg, filepath : '' }
      if (this.platform.is('capacitor')) {
        this.filePermissions = Filesystem.requestPermissions();
        this.cameraPermissions = Camera.requestPermissions();
      }
  }
  filePermissions //= Filesystem.requestPermissions();
  cameraPermissions //= Camera.requestPermissions();

  public async loadSaved() {

    const photoList = await  JSON.parse(localStorage.getItem(GS.store.photos));
    photoList ? this.photos = photoList : this.photos = {webviewPath : GS.defaultImg, filepath : '' }
    
    //If running on the web...
    if (!this.platform.is('capacitor') && this.photos.filepath !== '') {


        const readFile = await Filesystem.readFile({
          path: this.photos.filepath,
          directory: Directory.Data,
        });
        if (readFile) {
          this.photos.webviewPath = `data:image/jpeg;base64,${readFile.data}`;
        }
        //Web platform only: Load the photo as base64 data
    
    }
    this.isReady = true;
  }
  resetImg() {
    this.photos = {webviewPath : GS.defaultImg, filepath : '' }
    localStorage.removeItem(GS.store.photos)
  }

  
  public async addNewToGallery(type) {
    // const photo = await Camera.getPhoto({
    //   source: CameraSource.Prompt,
    //   saveToGallery: false,
    //   resultType: CameraResultType.DataUrl
    // });
    // this.imageSrc = photo.dataUrl;
    // console.log(photo);
    //  format: "jpeg"
    //  dataUrl: "data:image/jpeg;base64,..."
    //  exif: {
    //    ImageLength: "1944",
    //    ImageWidth: "2592",
    //    Orientation: "0"
    //  }
    // Take a photo


// en


// with translation

    
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri, // file-based data; provides best performance
      source: type == 'CAMERA' ? CameraSource.Camera : CameraSource.Photos
      , // automatically take a new photo with the camera
      quality: 100, // highest quality (0 to 100)
    });
    this.myNewPhoto = capturedPhoto

    const savedImageFile = await this.savePicture(capturedPhoto);
    
    // Add new photo to Photos array
    this.photos = savedImageFile;
    // Cache all photo data for future retrieval
    this.api.setToStorage(GS.store.photos, this.photos)
    return capturedPhoto;
  }

  // Save picture to file on device
  private async savePicture(cameraPhoto: Photo) {
    // Convert photo to base64 format, required by Filesystem API to save
    const base64Data = await this.readAsBase64(cameraPhoto);
    this.base64 = base64Data
    // Write the file to the data directory
    const fileName = new Date().getTime() + '.jpeg';
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data,
    });
    

    if (this.platform.is('capacitor')) {
      
      return {
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      };
    } else {
      // Use webPath to display the new image instead of base64 since it's
      // already loaded into memory
      //console.log( "fileName ###"+fileName, "cameraPhoto ###"+ cameraPhoto.webPath);

      return {
        filepath: fileName,
        webviewPath: cameraPhoto.webPath,
      };
    }
  }

  // Read camera photo into base64 format based on the platform the app is running on
  private async readAsBase64(cameraPhoto: Photo) {
    // "hybrid" will detect Cordova or Capacitor
    if (this.platform.is('capacitor')) {
      // Read the file into base64 format
      const file = await Filesystem.readFile({
        path: cameraPhoto.path,
      });
      //console.log(file);
      

      return file.data;
    } else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(cameraPhoto.webPath!);
      const blob = await response.blob();
      return (await this.convertBlobToBase64(blob)) as string;
    }
  }

  // Delete picture by removing it from reference data and the filesystem
  public async deletePicture(photo: UserPhoto, position: number) {
    // Remove this photo from the Photos reference data array
    // Update photos array cache by overwriting the existing photo array
    Preferences.set({
      key: this.PHOTO_STORAGE,
      value: JSON.stringify(this.photos),
    });

    // delete photo file from filesystem
    const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
    await Filesystem.deleteFile({
      path: filename,
      directory: Directory.Data,
    });
  }

  convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
}

export interface UserPhoto {
  filepath: string;
  webviewPath: string;
}

import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { GlobalSettings as GS } from './../global.settings';
import { AuthService } from 'src/app/services/auth.service';
import { environment as ENV } from './../../environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  
  constructor(
    public api: ApiService,
    public auth: AuthService,
    public load: LoadingController,
    private toast: ToastService,
    public router: Router,
  ) { }
  
  // The items the customer wants to buy
  idempotencyKey: string = this.makeUuid(8);
  
  clientSecret: any;
  paymentError: boolean;
  cardDetails: any;
  elements;
  cardData;
  amount;
  isReadyToPay: boolean = false;


  staticPage: boolean = false;

  makeUuid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // Fetches a payment intent and captures the client secret
  async initialize(stripe , total, doc) {
    this.amount = total;
    if(total <= 0) return 
    this.toast.loader(null ,null,10000)
    let data = {
      Amount: total,
      // currency: "€",
      IdempotencyKey: this.makeUuid(8),
      PaymentID: doc // lo passo per creare un ordine di tipo cart
    }
    const headerDict = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    }
        
    const requestOptions = {
      headers: new HttpHeaders(headerDict), 
    }

    this.api.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.stripeIntent, data, requestOptions ).subscribe((data)=> {
      if(!ENV.production) console.log(data);
      
      if (data) {


        this.clientSecret = data
        // const appearance = { 
        //   theme: 'stripe',
        //   variables: {
        //     colorPrimary: '#0570de',
        //     colorBackground: '#eeeeee',
        //     colorText: '#30313d',
        //     colorDanger: '#df1b41',
        //     fontFamily: 'Ideal Sans, system-ui, sans-serif',
        //     spacingUnit: '6px',
        //     borderRadius: '8px',
        //     fontSizeBase: '1.5rem',
        //     spacingTab: '6px'
        //   // See all possible variables below
        // } };
        // let clientSecret = this.clientSecret
        // this.elements = stripe.elements({ appearance, clientSecret });
        // this.cardData = this.elements.create("payment");
        // this.cardData.mount("#payment-element");
        
        let style = {
          base: {
              color: "#32325d",
              // lineHeight: "24px",
              //backgroundColor: 'green',
              fontSmoothing: "antialiased",
              fontSize: "20px",
              "::placeholder": {
                  color: "#aab7c4"
              }
          },
          invalid: {
              color: "#fa755a",
              iconColor: "#fa755a"
          }
        };      
      
        let elements = stripe.elements();
        this.cardData = elements.create("card", {style: style,  hidePostalCode: true });
        this.cardData.mount("#card-element");
        this.toast.dismissLoading()
       // console.log(this.cardData);
       this.isReadyToPay = true

        const form = document.getElementById("payment-form");
          form.addEventListener("submit", (event) => {
            event.preventDefault();
            //paymentIntent 3DSecure
            this.handleSubmit(stripe);
          });
          
      }
      
        
        
      },(err)=> {
        if (!ENV.production) console.log(err)
    })
      
  }
  
  async handleSubmit( stripe) {
    this.toast.loader(null,null, 50000)

    return await this.stripeHandleConfirmPayment( stripe, this.clientSecret).then(result => {

      if (result) {
        if (!ENV.production)
          console.log('stripeHandleCardPayment', result);
        if (result.error) {
          //errorElement.textContent = result.error.message;
          this.paymentError = true;
          this.toast.dismissLoading()
          return {
            success: false,
            msg: result.error.message
          }
        } else {
          // //stato possibile:requires_payment_method, requires_confirmation, requires_action, processing, requires_capture, canceled
          //console.log('stripeHandleCardPayment status',result.paymentIntent.status);
          if (result.paymentIntent.status === 'requires_capture') {
            this.paymentError = false;
            const paymentID = result.paymentIntent.id;
            //this.toast.present('Pagamento riuscito')
            const req = {Amount: this.amount, IdempotencyKey: "", PaymentID: paymentID  }
            return {
              req : req,
              success: true,
              msg: ""
            }
            //this.router.navigate([GS.pages.bills], {replaceUrl: true})

            
          }
          else {
            this.paymentError = true;
            this.toast.dismissLoading()
            return {
              success: false,
              msg: result.error.message
            }

          }
        }
      } else {
        this.paymentError = true;
        this.toast.dismissLoading()
        return {
          success: false,
          msg: result.error.message
        }

      }
    })

  }

  stripeConfirmIntent(req) {
    return this.api.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.stripeConfirm, req)
  }
  scalaPaySendRequest(req) {
    return this.api.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.scalaPayLink, req)
  }
  scalaPayCreateOrder(req) {
    return this.api.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.scalaPayCreate, req)
  }
  scalaPayStatusOrder(req) {
    return this.api.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.scalaPayStatus, req)
  }
  scalaPayCaptureOrder(req) {
    return this.api.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.scalaPayCapture, req)
  }


  async stripeHandleConfirmPayment( stripe, clientSecret): Promise<any> {
    const recap = await JSON.parse(localStorage.getItem(GS.store.recap));
    

    return new Promise(resolve => {
      //let resp;
      stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.cardData,
          billing_details: {
            name: recap.lastname + ' ' + recap.firstname,
          },
        },
      })
      .then((result)=> {
        // Handle result.error or result.paymentIntent
        if (result.error){ 
          this.toast.presentClose(result.error.message, false)
        }
        if(!ENV.production) console.log('%c Stripe result','background: purple',result);
        //resp = result;
        resolve(result);
      });
    });
  }



}
export interface Intent {
  Model: any,
  Success: any
}
export interface CartInfo {
  products: CartItem[],
  //md5: string,
  total:number,
  totalDiscounted?:number,
  storeId: string,
  userId?: string,
  delivery?: string,
  hasDiscount?: boolean,
  lastSync?: number
}
export interface CartItem {
  prodId?: string, //quellicon ? messi cosi perchè li restituisco anche con ordini
  //catId: string,
  name: string,
  qty: number,
  price?: number,
  priceDiscounted?:number,
  currency?: string

}


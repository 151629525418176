import { PdaService } from 'src/app/services/pda.service';
import { PhotoService } from './photo.service';
import { GlobalSettings as GS } from './../global.settings';
import {  Support, TicketReq } from './../models/support.model';
import { ToastController } from '@ionic/angular';
import { environment as ENV } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders } from '@angular/common/http';
import { Preferences } from '@capacitor/preferences';
import { InvoiceReq } from '../models/pda.model';
import { HomeNews, HomeSlider } from '../models/home.model';
import { ActionSheetOption } from '@ionic/pwa-elements/dist/types/definitions';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  constructor(public http : HttpClient,
    public toast: ToastController,
    ) { }
    
  header = new HttpHeaders({});
  chartDate: InvoiceReq;
  iconSet;
  
  stored = {
    isDark: null,
    privacyUrl: '',
    lastSync: null,
    //iconColor: 'var(--ajo-primary-dark)',
    faq: [],
    filteredfaq: [],
    news: [],
    slides: [],
    homeNews: [],
    offers: [],
    manuals: [],
    links: {
      whatsapp: '',
      email: '',
      phone: ''    
    },
    recap: {
      id: '' as string | number ,
      firstname:  '' as string | boolean ,
      lastname: '' as string | boolean ,
      email: '',
      enerp_customer_code: '' as string | number
    },
    calendar: '',
    pageNews: {
      bills: [],
      utilities: [],
      faq_details: [],
      faq_index: [],
      self_reading: [],
      settings: [],
      support: []
    },
    push: true
  }
  personal = {}

  isReady = {
    faq: true,
    news: true,
    slides: true,
    homeNews: true,
    offers: true,
    manuals: true,
    links: true,
    recap: true,
    bills: true
  }

  fbToken;



  get token(): string {
    const t = JSON.parse(localStorage.getItem(GS.store.user));   
    return !!t ? t?.stsTokenManager.accessToken : ''
  }
  
  get icons() {
    return this.iconSet
  }
  set icons(i) {
    this.iconSet = i
  }

  get language() {
    return GS.DEFAULT_LANG == 'IT' ? '' : '/en'
  }
  getSupportInfo() {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.contacts , {} , {headers: this.header} )
  }
  getOffers() {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.offers + this.language , {} , {headers: this.header} )
  }
  getManuals() {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.manuals + this.language , {} , {headers: this.header} )
  }
  sendTicket(req: TicketReq) {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.ticket , req , {headers: this.header} )
  }
  getfaq() {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.faq + this.language, { } , {headers: this.header} )
  }
  getBoarding() {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.welcome + this.language, {}, {headers: this.header} )
  }
  getSlide() {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.homeslider + this.language, {}, {headers: this.header} )
  }
  getHomeNews()  {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.news + this.language, {}, {headers: this.header} )
  }
  sendSelfCheck(req) {
    return this.http.post(ENV.api.BASE_URL_ID + ENV.api.endpoint.sendSelf , req, {headers: this.header} )
  }
  

  getSelfCheck() {
    return this.http.get(ENV.api.BASE_URL + ENV.api.selfcheck.path + ENV.api.selfcheck.info )
  }
  getIcons() {
    return this.http.get(ENV.api.icons.path + ENV.api.icons.icons )
  }
  // getModalInfo() {
  //   return this.http.get(ENV.api.BASE_URL + ENV.api.boarding.path + ENV.api.boarding.boarding ).pipe(
  //     map((data) => data['modal'])
  //   )
  // }
  // getUser(): Observable<Customer>{
  //   return this.http.get(ENV.api.BASE_URL + ENV.api.customerInfo).pipe(
  //     map((res: User) =>  res.result.customer)
  //   )
  // }
  // getData() {
  //   return this.http.get(ENV.api.BASE_URL + ENV.api.home.path + ENV.api.home.chart )
  // }

/**
 * salva i dati nello storage 
 * @param k Chiave dell'oggetto
 * @param val Valore dell'oggetto
 * 
 */

  async setToStorage(k, val) {
    localStorage.setItem(k, JSON.stringify(val));
  };

  async getFromStorage (k): Promise<any> {
    return JSON.parse(localStorage.getItem(k));
  };
  async removeFromStorage (k){
     await Preferences.remove({ key: k });
  };

  findRangeDate() {
    let d = new Date()
    let date = (new Date()).toISOString().split('T')[0];
    d.setMonth(d.getMonth()-7);
    let od = (d).toISOString().split('T')[0];
    //console.log(date, od);
    this.chartDate = {from: od, to: date}
  }
  getSlideFromServer() {
    this.isReady.slides = false

    this.getSlide().subscribe((res: HomeSlider[] ) => {
      this.stored.slides = res
      this.setToStorage(GS.store.slides, this.stored.slides)
      this.isReady.slides = true
    })
  }

  getHomeNewsFromServer() {
    this.isReady.homeNews = false

    this.getHomeNews().subscribe((res: HomeNews[]) => {
      this.newsHelper(res)
      this.stored.homeNews = res.filter((r) => r.featured == true)
      this.setToStorage(GS.store.homeNews, this.stored.homeNews)
      this.isReady.homeNews = true

    })
  }

  getFaqFromServer() {
    this.isReady.faq = false

    this.getfaq().subscribe(data =>{ 
      this.stored.faq = this.stored.filteredfaq =  data['categories']
      this.setToStorage(GS.store.faq, this.stored.faq )
      this.isReady.faq = true
    })
  }
  getNewsFromServer() {
    this.isReady.news = false
    this.getHomeNews().subscribe((res: HomeNews[]) => {
      this.stored.news = res
      this.setToStorage(GS.store.news , this.stored.news )
      this.isReady.news = true
    })
    
  }
  getOffersFromServer() {
    this.isReady.offers = false

    this.getOffers()
    .subscribe((res: HomeNews[]) => {
      this.stored.offers = res
      this.setToStorage(GS.store.offers , this.stored.offers )
      this.isReady.offers = true

    })
  }

  getManualFromServer() {
    this.isReady.manuals = false

    this.getManuals().subscribe( (res) => {
      this.stored.manuals = res['categories']
      this.setToStorage(GS.store.manuals ,this.stored.manuals )
      this.isReady.manuals = true

    } )
  }
  getLinksFromServer() {
    this.isReady.links = false

    this.getSupportInfo().subscribe((res: Support)=>{ 
      this.stored.links = res.support
      this.stored.links.whatsapp  = GS.whatsapp+ GS.prefix + res.support.whatsapp
      this.setToStorage(GS.store.links ,this.stored.links )
      this.isReady.links = true

    })
  }
  /**
   * Esegue un log delle le prime e le ultime cifre del token Firebase
   * 
   * @param token 
   *  
   */
  logToken(token:string) {
    const t = token
    let last = t.split('').reverse().join("").substring(0, 5).split('').reverse().join("")
    let first = t.substring(0, 5)
    return first+'...'+last;
  }

  /**
   * Filtra le news per ogni pagina e le salva nello storage
   * @param res 
   */
  newsHelper(res: HomeNews[]) {
    for (const key in this.stored.pageNews) {
      if (Object.prototype.hasOwnProperty.call(this.stored.pageNews, key)) {
        this.stored.pageNews[key] = res.filter((r) => { r?.page?.toLowerCase() == (key.toLowerCase().includes('_') ? key.toLowerCase().replace('_', '-') : key.toLowerCase())})
      }
    }
    this.setToStorage('pageNews', this.stored.pageNews)
  }




  
}

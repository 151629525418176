import { TranslateService } from '@ngx-translate/core';
import { GlobalSettings as GS } from './../../../global.settings';
import { ToastService } from './../../../services/toast.service';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { OtpResponse } from 'src/app/models/auth.model';
import { environment as ENV } from 'src/environments/environment';
import { Clipboard } from '@capacitor/clipboard';


@Component({
  selector: 'app-forgot-email',
  templateUrl: './forgot-email.component.html',
  styleUrls: ['./forgot-email.component.scss'],
})
export class ForgotEmailComponent implements OnInit {

  isSubmit: boolean = false;
  checkForm : FormGroup
  isSendOk = false;
  userId = "";
  myEmail: any = "";
  secretCode: any 

  constructor(
    public modalController: ModalController,
    public auth : AuthService,
    public toast: ToastService,
    private translate: TranslateService
  ) { 
    this.inputControl()
  }
  inputControl() {
    
    this.checkForm = new FormGroup({
      userId: new FormControl('', Validators.required ),
      otpCode: new FormControl('', Validators.required )
    })

  }
  ngOnInit() {}
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async copy() {
      await Clipboard.write({
        string: this.myEmail
      }).then(()=> {
        this.translate.get('LOGIN.email_restore.copied_mail').subscribe((res)=> {
          this.toast.present(res)

        })
      })
  }
 


  recoverySubmit() {
    this.isSubmit = true
    if (this.checkForm.valid ) {
      let req = {
        CustomerId : this.checkForm.value.userId+"",
        OtpCode : this.checkForm.value.otpCode+""
      }
      this.auth.checkOtp(req).subscribe((data: OtpResponse)=> {
        if (data.success) {
          this.myEmail = data.data.email
        }
      },(err) => {
        this.translate.get('ERROR.otp_'+ err.error.status ).subscribe((data)=> {
          this.toast.present(data, false)
        })
      })
    }
  }


  sendCode() {
    this.isSubmit = true
    if (this.checkForm.value.userId !== "") {
      let req = {
        CustomerId : this.checkForm.value.userId+"",
        OtpCode : ""
      }
      this.toast.loader()
      //"OtpCode": "183795"
      this.auth.sendOtp(req).subscribe((data: OtpResponse)=> {
      // console.log(data);
      this.toast.dismissLoading();
      if (data.success) {
        if (ENV.sandboxIV) {
          this.toast.presentClose(`Ajo Sms ${data.messages} ${data.data}`)
          this.secretCode = data.data
          this.checkForm.controls.otpCode.setValue(data.data)
        }
        this.isSendOk = true
  
      } else {
        this.toast.present(data.messages[0] , false)
      }
      this.isSubmit = false
        
      },(err) => {
        this.isSubmit = false

          this.translate.get('ERROR.otp_'+ err.error.status ).subscribe((data)=> {
            this.toast.present(data, false)
          })
      });
    }
  }
  checkValidate(type) {
    if (this.isSubmit) {
      return this.checkForm.hasError('required', type) 
    }
  }

}

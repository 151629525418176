import { AuthService } from 'src/app/services/auth.service';
import { ContactusComponent } from './../../modals/contactus/contactus.component';
import { ToastService } from './../../../services/toast.service';
import { NewUser } from './../../../models/auth.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators }  from '@angular/forms';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { InfoBoardingComponent } from '../../modals/info-boarding/info-boarding.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSettings as GS } from 'src/app/global.settings';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {

  constructor(public modalController: ModalController,
    private translate: TranslateService,
    private routerOutlet: IonRouterOutlet, 
    public router: Router,
    public toast: ToastService,
    public auth: AuthService,
    private iab: InAppBrowser,
    private api: ApiService
    ) {
    this.inputControl() 
  }
  inputType = GS.input_type.password
  rptType = GS.input_type.password
  afSubmit = false;
  steps = 0
  modal = {}
  activationForm: FormGroup;
  showError = false
  isChecked;
  pattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/);
  @Output() isSubmit = new EventEmitter<NewUser>();

  ngOnInit() {}
  inputControl() {
    this.activationForm = new FormGroup({
      userCode: new FormControl(this.auth.userCode, Validators.required),
      activeCode: new FormControl(this.auth.secretCode, Validators.required),
      password: new FormControl('', Validators.compose([
        Validators.required, Validators.minLength(8),Validators.pattern(this.pattern)] )),
      rptPassword: new FormControl('', Validators.required),
      privacy: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("true")
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(GS.email_pattern)
      ])),
      rptemail: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(GS.email_pattern)
      ]))
    });

  }
  get validator() {
    const validator = this.activationForm.get('privacy').validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  goToPrivacyPolicy() {
    this.iab.create(this.api.stored.privacyUrl, '_self')
  }
  activSubmit() {
    this.afSubmit = true;
    if (!this.pattern.test(this.activationForm.value.password)) {
      this.translate.get('MESSAGE.signup').subscribe((res)=> {
        this.toast.present(res, false, 5000)
      })
    }
    
    if (this.activationForm.valid && this.activationForm.value.password == this.activationForm.value.rptPassword && 
      this.activationForm.value.email == this.activationForm.value.rptemail) {
      let req = {
        Activationcode: this.activationForm.value.activeCode,
        Password: this.activationForm.value.password,
        Email: this.activationForm.value.email,
        Phonenumber: '',
        Customerid:  this.activationForm.value.userCode,
        Name: '',
        Surname: ""
      }
      this.isSubmit.emit(req)
    }
  }

  checkValidate(form, submit , ctr) {
    if (submit) {
      return form.hasError('required' , ctr) || form.hasError('pattern' , ctr) || form.hasError('minlength' , ctr)
    }
  }
  checkPsw() {
    if (this.activationForm.value.password.length >= 1 && !this.pattern.test(this.activationForm.value.password)) {
      return false
    } else {
      return true
    }
  }
  async presentModal() {
    const modal = await this.modalController.create({
      component: InfoBoardingComponent,
      presentingElement: this.routerOutlet.nativeEl,
      mode: 'ios'
    });
    return await modal.present();
  }
  async missingCode() {
    const modal = await this.modalController.create({
      component: ContactusComponent,
      presentingElement: this.routerOutlet.nativeEl,
      mode: 'ios'
    });
    return await modal.present();
  }
  goToLogin() {
    this.router.navigate([GS.pages.login], {replaceUrl: true})
  }
  getActivationCode() {
    this.router.navigate([GS.pages.get_code], {replaceUrl: true})
  }
  checkPassword() {
    return this.activationForm.value.password !== this.activationForm.value.rptPassword
  }
  checkEmail() {
    return this.activationForm.value.email !== this.activationForm.value.rptemail
  }
  togglePasswordMode(isrepeat = false)
  {
    if (isrepeat) {
      this.rptType = this.rptType === GS.input_type.text ? GS.input_type.password : GS.input_type.text;
    } else {
      this.inputType = this.inputType === GS.input_type.text ? GS.input_type.password : GS.input_type.text;
    }
  }

}


